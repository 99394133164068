import React from "react"
import PropTypes from "prop-types"
import Icon from "../Icon"
import Loading from "../Loading"

import styles from "./styles.module.scss"

const ButtonCustom = ({
  children,
  handleClick,
  hierarchy,
  size,
  icon,
  iconPulse = false,
  hiddenByDefault = false,
  loading = false,
  className,
  iconClassName,
  ...other
}) => {
  const hierarchyClass = hiddenByDefault
    ? styles[`${hierarchy}Hidden`]
    : styles[hierarchy]

  return (
    <button
      className={`
      ${styles.button}
      ${hierarchyClass}
      ${styles[size]}
      ${loading && styles.loading}
      ${className}
      ${iconPulse && styles.pulse}
    `}
      onClick={handleClick}
      {...other}
    >
      {loading && <Loading />}
      <span className={`${loading && styles.contentLoading}`}>
        {icon && (
          <Icon aesthetic="minimal" icon={icon} className={iconClassName} />
        )}
        {children}
      </span>
    </button>
  )
}

ButtonCustom.defaultProps = {
  hierarchy: "secondary",
  loading: false,
}

ButtonCustom.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  handleClick: PropTypes.func,
  hierarchy: PropTypes.oneOf(["primary", "secondary", "dark"]),
  loading: PropTypes.bool,
  hiddenByDefault: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]).isRequired,
}

export default ButtonCustom
