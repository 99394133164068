import { gql } from "@apollo/client"

export const get_organizations = gql`
  query {
    organizations {
      organization_id
      name
    }
  }
`
