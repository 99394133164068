import React, { useState } from "react"
import ButtonCustom from "../../ButtonCustom"
import Icon from "../../Icon"
import { Checkbox } from "antd"
import styles from "../styles.module.scss"
import { formatMoney } from "../../../utils/format"
import Loading from "../../Loading"
import { getTransactionFees } from "../../../../functions/utils/calc"
import { CreditCardSvg, BitcoinSvg } from "../icons"
import redirectToGeneralFundCheckout from "../../../utils/redirect-general-fund"
import { CustomInputNumber } from "../../CustomInputNumber"

export const GeneralFundContent = ({ stage, setStage }) => {
  const [value, setValue] = useState(null) //null
  const [recurring, setRecurring] = useState(false)
  const [isCoveringFees, setIsCoveringFees] = useState(true)
  const [subscribeMail, setSubscribeMail] = useState(true)
  const [paying, setPaying] = useState(false)
  const [payingCrypto, setPayingCrypto] = useState(false)

  const onFinish = values => {
    if (!paying) {
      redirectToGeneralFundCheckout(
        value,
        recurring,
        subscribeMail,
        values.name,
        values.email,
        isCoveringFees
      )
      setPaying(true)
    }
  }

  const onFinishCrypto = values => {
    if (!setPayingCrypto) {
      redirectToGeneralFundCheckout(
        value,
        recurring,
        subscribeMail,
        values.name,
        values.email,
        false,
        true
      )
      setPayingCrypto(true)
    }
  }

  const AmountChip = ({ value, selectChip, selected }) => (
    <div
      className={`${styles.amountChip} ${selected &&
        styles.amountChipSelected}`}
      onClick={selectChip}
    >
      <span>{formatMoney(value)}</span>
    </div>
  )

  switch (stage) {
    case 0:
      return (
        <>
          <div className={styles.header}>
            <Icon icon="unclassified" aesthetic="simple" />
            <h3 className={styles.heading}>Give to The NeighborShare Fund</h3>
            <p className={styles.subheading}>
              By donating to the general fund, NeighborShare will select
              households to help on your behalf.
            </p>
          </div>
          <div className={styles.actions}>
            <p className={styles.selectText}>Select an amount</p>
            <div className={styles.chipContainer}>
              <AmountChip
                value={10}
                selectChip={() => setValue(10)}
                selected={value === 10}
              />
              <AmountChip
                value={25}
                selectChip={() => setValue(25)}
                selected={value === 25}
              />
              <AmountChip
                value={50}
                selectChip={() => setValue(50)}
                selected={value === 50}
              />
            </div>
            <CustomInputNumber value={value} setValue={setValue} />
            <div className={styles.applyDonation}>
              <div className={styles.checkboxes}>
                <Checkbox
                  checked={recurring}
                  onClick={() => setRecurring(!recurring)}
                >
                  Make this a monthly donation
                </Checkbox>
                <Checkbox
                  checked={subscribeMail}
                  onClick={() => setSubscribeMail(!subscribeMail)}
                >
                  Stay updated by subscribing to NeighborShare news
                </Checkbox>
                <div className={`${styles.text} ${styles.further}`}>
                  Help your gift go further!
                </div>
                <Checkbox
                  checked={isCoveringFees}
                  onClick={() => setIsCoveringFees(!isCoveringFees)}
                >
                  Cover transaction fee
                  {!value || value === 0
                    ? ""
                    : ` ($${getTransactionFees(value).toFixed(2)})`}
                </Checkbox>
              </div>
              <ButtonCustom
                className={styles.checkoutButton}
                iconClassName={styles.checkoutButtonIcon}
                size="medium"
                onClick={() => {
                  if (recurring) {
                    setPaying(true)
                    onFinish({
                      name: null,
                      email: null,
                    })
                  } else {
                    value && setStage(stage + 1)
                  }
                }}
              >
                {paying ? <Loading /> : "Continue to Payment"}
              </ButtonCustom>
            </div>
          </div>
        </>
      )
    case 1:
      return (
        <>
          <div className={styles.header}>
            <div className={styles.dollarMonthlyDonation}>
              <span className={styles.dollarText}>${value.toFixed(2)}</span>
              <span className={styles.donationText}>
                {recurring ? `Monthly Donation` : null}
              </span>
            </div>
            <p className={styles.headerSubtitle} style={{ marginTop: "1rem" }}>
              Select a payment method to continue with your{" "}
              {recurring ? "monthly" : ""} donation to the General Fund.
            </p>
          </div>
          <div className={styles.donationFormWrapper}>
            <p className={styles.selectPaymentText}>Select a payment method</p>

            <div className={styles.payWithCard} onClick={onFinish}>
              <CreditCardSvg />
              {paying ? (
                <Loading />
              ) : (
                <p className={styles.payWithCardText}>Pay with card</p>
              )}
            </div>

            <div className={styles.payWithCard} onClick={onFinishCrypto}>
              <BitcoinSvg />
              {payingCrypto ? (
                <Loading />
              ) : (
                <p className={styles.payWithCardText}>Pay with crypto</p>
              )}
            </div>
          </div>
        </>
      )
  }
}
