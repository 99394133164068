import { makeBulkGiftCardPurchase } from "../api"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"

const stripePublicKey = process.env.GATSBY_STRIPE_PUBLIC_KEY

const redirectToBulkGiftCardCheckout = async (
  value,
  subscribeMail,
  bulkPurchaserName,
  isCoveringFees
) => {
  if (!value || !bulkPurchaserName) {
    // TODO: expose and handle bad data error
    console.warn(`invalid checkout`)
  }

  const sessionId = await makeBulkGiftCardPurchase(
    value,
    subscribeMail,
    bulkPurchaserName,
    isCoveringFees
  )

  const stripe = await loadStripe(stripePublicKey)
  const { error } = await stripe.redirectToCheckout({
    sessionId,
  })

  if (error) {
    await navigate("/error-page")
  }
}

export default redirectToBulkGiftCardCheckout
