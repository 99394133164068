import React from "react"
import IconsMinimal from "./IconsMinimal"
import IconsSimple from "./IconsSimple"
import IconsComplex from "./IconsComplex"
import IconsGeneric from "./IconsGeneric"

const styles = {
  display: "none",
}

const AllIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={styles}>
      <IconsMinimal />
      <IconsSimple />
      <IconsComplex />
      <IconsGeneric />
    </svg>
  )
}

export default AllIcons
