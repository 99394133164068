import { useState, useCallback } from "react"

export const useFamilies = () => {
  const [value, setValue] = useState([])
  const [helped, setHelped] = useState(0)

  const setCountHelped = useCallback(count => setHelped(count), [])
  const setCurrentValue = useCallback(allFamilies => setValue(allFamilies), [])

  return { value, setCurrentValue, helped, setCountHelped }
}
