import React from "react"
import LinkCustom from "../LinkCustom"

import styles from "./styles.module.scss"

export const menuItems = [
  {
    label: "Browse Needs",
    url: "/#explore-needs",
  },
  {
    label: "About Us",
    url: "/about-us",
  },
  {
    label: "News",
    url: "/news",
  },
  {
    label: "FAQs",
    url: "/faqs",
  },
  {
    label: "Our Team",
    url: "/team",
  },
  {
    label: "Resources",
    url: "/resources",
  },
  {
    label: "Donate",
    url: "/#",
  },
]

const InstitutionalMenu = () => {
  const Items = () =>
    menuItems.map((item, index) => (
      <li className={styles.menu__item} key={`${item.label}-${index}`}>
        <LinkCustom size="nav" url={item.url} title={item.label}>
          {item.label}
        </LinkCustom>
      </li>
    ))

  return (
    <ul className={styles.menu}>
      <Items />
      {/*<RegionSelector />*/}
    </ul>
  )
}

export default InstitutionalMenu
