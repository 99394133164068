import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
// components
import AllIcons from "../components/Icon/icons"
import Ctx from "../context"
// hooks
import { useFamilies, useFamiliesIds, useFamily, useFilters } from "../hooks"
import "../index.scss"

// functions
import { generateGeoJSON, getAllFamiliesIds } from "../utils"
import { GET_FAMILIES_REGION } from "../queries/allFamiles"
import { GET_NEAREST_REGION_ID } from "../queries/nearestRegion"
import { GET_SPONSOR_INFO_BY_NAME } from "../queries/getSponsorInfoByName"

import styles from "./styles.module.scss"
import { NeedsContextProvider } from "../context/needsContext"

import { get_organizations } from "../queries/getOrganizations"

const FAMILY_PAGE_SIZE = 1000 // hack! need to pull a lot of families now until we figure out filtering on the API.

export default ({ children, location: { pathname } }) => {
  const allFamilies = useFamilies()
  const closedFamilies = useFamilies()
  const allFamiliesIds = useFamiliesIds()
  const family = useFamily()
  const filters = useFilters()
  // const sponsorFilters = useSponsorFilters()

  // hacky fix, should try to pass in name of organization through filters.filters.organizations
  const { data: organizationsData } = useQuery(get_organizations)

  const organizations = (organizationsData?.organizations || []).map(org => ({
    value: org.organization_id,
    text: org.name,
  }))

  let selectedOrg = organizations.find(
    org => org.value === filters.filters.organizations[0]
  )

  if (selectedOrg) selectedOrg = selectedOrg.text

  const familiesVariables = {
    limit: FAMILY_PAGE_SIZE,
    offset: 0,
  }
  const sponsorName =
    pathname.split("/")[1] === "sponsor" ? pathname.split("/").pop() : null
  if (filters.filters.region) {
    familiesVariables.region_id = filters.filters.region
  }
  const { data, error } = useQuery(
    GET_FAMILIES_REGION(null), // nrm 11/20/2021 - default to all
    {
      variables: familiesVariables,
    }
  )
  if (error) {
    navigate("/error-page")
  }

  useEffect(() => {
    filters.resetFiltersExceptRegion()
    // sponsorFilters.resetSponsorFiltersExceptRegion()
  }, [data])

  // DO NOT include allFamilies or allFamiliesIds as dependencies for it will cause infinite loop
  useEffect(() => {
    if (!data) return
    allFamilies.setCountHelped(
      data.families.filter(
        f =>
          f.needs.filter(n => n.is_available === true).length < f.needs.length
      ).length
    )
    const familiesInNeed = data.families
      .map(x => ({
        ...x,
        needs: x.needs.filter(
          x =>
            x.value >= filters.filters.priceRange[0] &&
            x.value <= filters.filters.priceRange[1]
        ),
      }))
      .map(x => ({
        ...x,
        needs: x.needs.filter(
          x =>
            filters.filters.needs.includes(x.need_type) ||
            filters.filters.needs.length === 0
        ),
      }))
      .filter(
        t =>
          filters.filters.locations.includes(t.location_text) ||
          filters.filters.locations.length === 0
      )
      .filter(t => t.needs.filter(x => x.is_available === true).length > 0)
      .filter(
        t =>
          t.organization.name === selectedOrg ||
          typeof selectedOrg === "undefined"
      )
      .map(family => ({
        ...family,
        // sort algo:
        // average card value
        // * random number between 0.8 and 1.0
        // * minimum card value
        sortValue: Math.floor(
          (family.needs.reduce((a, b) => a + b.value, 0) /
            family.needs.length) *
            (Math.random() * (1.0 - 0.8) + 0.8) *
            Math.min(...family.needs.map(v => v.value))
        ),
      }))
      .sort((a, b) => a.sortValue - b.sortValue)
    const closedNeedFamilies = data.families.filter(
      t => t.needs.filter(x => x.is_available === true).length === 0
    )
    allFamilies.setCurrentValue(familiesInNeed)
    closedFamilies.setCurrentValue(closedNeedFamilies)

    allFamiliesIds.setCurrentValue(getAllFamiliesIds(familiesInNeed))
  }, [
    filters.filters.priceRange,
    filters.filters.needs,
    filters.filters.locations,
    filters.filters.distanceRange,
    filters.filters.organizations,
    data,
  ]) // eslint-disable-line

  // useEffect(() => {
  //   if (!data) return
  //   allFamilies.setCountHelped(
  //     data.families.filter(
  //       f =>
  //         f.needs.filter(n => n.is_available === true).length < f.needs.length
  //     ).length
  //   )

  //   const sponsorFamiliesInNeed = data.families
  //     .map(x => ({
  //       ...x,
  //       needs: x.needs.filter(
  //         x =>
  //           x.value >= sponsorFilters.sponsorFilters.priceRange[0] &&
  //           x.value <= sponsorFilters.sponsorFilters.priceRange[1]
  //       ),
  //     }))
  //     .map(x => ({
  //       ...x,
  //       needs: x.needs.filter(
  //         x =>
  //           sponsorFilters.sponsorFilters.needs.includes(x.need_type) ||
  //           sponsorFilters.sponsorFilters.needs.length === 0
  //       ),
  //     }))
  //     .filter(
  //       t =>
  //         sponsorFilters.sponsorFilters.locations.includes(t.location_text) ||
  //         sponsorFilters.sponsorFilters.locations.length === 0
  //     )
  //     .filter(t => t.needs.filter(x => x.is_available === true).length > 0)
  //     .map(family => ({
  //       ...family,
  //       // sort algo:
  //       // average card value
  //       // * random number between 0.8 and 1.0
  //       // * minimum card value
  //       sortValue: Math.floor(
  //         (family.needs.reduce((a, b) => a + b.value, 0) /
  //           family.needs.length) *
  //         (Math.random() * (1.0 - 0.8) + 0.8) *
  //         Math.min(...family.needs.map(v => v.value))
  //       ),
  //     }))
  //     .sort((a, b) => a.sortValue - b.sortValue)

  //   const closedNeedFamilies = data.families.filter(
  //     t => t.needs.filter(x => x.is_available === true).length === 0
  //   )
  //   allFamilies.setCurrentValue(sponsorFamiliesInNeed)
  //   closedFamilies.setCurrentValue(closedNeedFamilies)

  //   allFamiliesIds.setCurrentValue(getAllFamiliesIds(sponsorFamiliesInNeed))
  // }, [
  //   sponsorFilters.sponsorFilters.needs,
  //   sponsorFilters.sponsorFilters.locations,
  //   sponsorFilters.sponsorFilters.distanceRange,
  //   data,
  // ]) // eslint-disable-line

  return (
    <div className={styles.homepage}>
      <AllIcons />
      <Ctx.Provider
        value={{
          allFamilies,
          allFamiliesIds,
          family,
          closedFamilies,
          filters,
          // sponsorFilters,
        }}
      >
        <NeedsContextProvider>
          <>
            <Header pathname={pathname} />
            <main>{children}</main>
            <Footer path={pathname} />
          </>
        </NeedsContextProvider>
      </Ctx.Provider>
    </div>
  )
}
