import React from "react"

const IconsGeneric = () => {
  return (
    <>
      <symbol id="generic-404" viewBox="0 0 100.23 34.56">
        <path d="M0,29.28V20.4L14.3,0H25.78V20.06h3.45v9.22H25.78v5.28H15V29.28ZM15.89,12.14,10.7,20.06h5.19Z" />
        <path d="M71,29.28V20.4L85.3,0H96.78V20.06h3.45v9.22H96.78v5.28H86V29.28ZM86.89,12.14,81.7,20.06h5.19Z" />
        <path d="M50.84,34.56s8.27-7.45,11.87-12.28c2.9-3.72,4.57-6.14,4.57-11a7.56,7.56,0,0,0-7.82-7.75c-5.28,0-8.62,7.36-8.62,7.36S47.5,3.56,42.23,3.56a7.68,7.68,0,0,0-7.83,7.75c0,4.93,1.67,7.25,4.57,11C42.58,27.11,50.84,34.56,50.84,34.56Z" />
      </symbol>
    </>
  )
}

export default IconsGeneric
