import { makeGeneralFundDonation } from "../api"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"

const stripePublicKey = process.env.GATSBY_STRIPE_PUBLIC_KEY

const redirectToGeneralFundCheckout = async (
  value,
  recurring,
  subscribeMail,
  name,
  email,
  isCoveringFees,
  crypto
) => {
  if (!value) {
    // TODO: expose and handle bad data error
    console.warn(`invalid checkout`)
  }

  const sessionId = await makeGeneralFundDonation(
    value,
    recurring,
    subscribeMail,
    name,
    email,
    isCoveringFees
  )

  if (crypto) {
    await navigate(
      "https://commerce.coinbase.com/checkout/8e1b9aed-02c1-401f-9bc3-df81da40a6b7"
    )
  } else {
    const stripe = await loadStripe(stripePublicKey)
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    })

    if (error) {
      await navigate("/error-page")
    }
  }
}

export default redirectToGeneralFundCheckout
