import React from "react"

export const CreditCardSvg = () => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.25 0.125H2.75C2.12011 0.125 1.51602 0.375222 1.07062 0.820621C0.625222 1.26602 0.375 1.87011 0.375 2.5V7.3836V21.4852C0.375 22.115 0.625222 22.7191 1.07062 23.1645C1.51602 23.6099 2.12011 23.8602 2.75 23.8602H31.25C31.8799 23.8602 32.484 23.6099 32.9294 23.1645C33.3748 22.7191 33.625 22.115 33.625 21.4852V2.5C33.625 1.87011 33.3748 1.26602 32.9294 0.820621C32.484 0.375222 31.8799 0.125 31.25 0.125ZM18.1875 19.125H15.8125C15.4976 19.125 15.1955 18.9999 14.9728 18.7772C14.7501 18.5545 14.625 18.2524 14.625 17.9375C14.625 17.6226 14.7501 17.3205 14.9728 17.0978C15.1955 16.8751 15.4976 16.75 15.8125 16.75H18.1875C18.5024 16.75 18.8045 16.8751 19.0272 17.0978C19.2499 17.3205 19.375 17.6226 19.375 17.9375C19.375 18.2524 19.2499 18.5545 19.0272 18.7772C18.8045 18.9999 18.5024 19.125 18.1875 19.125ZM27.6875 19.125H22.9375C22.6226 19.125 22.3205 18.9999 22.0978 18.7772C21.8751 18.5545 21.75 18.2524 21.75 17.9375C21.75 17.6226 21.8751 17.3205 22.0978 17.0978C22.3205 16.8751 22.6226 16.75 22.9375 16.75H27.6875C28.0024 16.75 28.3045 16.8751 28.5272 17.0978C28.7499 17.3205 28.875 17.6226 28.875 17.9375C28.875 18.2524 28.7499 18.5545 28.5272 18.7772C28.3045 18.9999 28.0024 19.125 27.6875 19.125ZM2.75 6.19609V2.5H31.25V6.19609H2.75Z"
      fill="#81829E"
    />
  </svg>
)
