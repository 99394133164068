import React from "react"
import PropTypes from "prop-types"

const Logo = ({ size }) => (
  <>
    {size === "minimal" ? (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
          style={{ fill: "var(--color-cobalt)" }}
          d="M16,29s7-6.25,10.11-10.3C28.58,15.58,30,13.55,30,9.5A6.4,6.4,0,0,0,23.34,3C18.85,3,16,9.18,16,9.18S13.15,3,8.66,3A6.48,6.48,0,0,0,2,9.5c0,4.14,1.42,6.08,3.89,9.2C9,22.75,16,29,16,29Z"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 662 89">
        <g style={{ fill: "var(--color-navy)" }}>
          <polygon points="36.56 38.64 17.76 9.68 0 9.68 0 66.32 17.76 66.32 17.76 37.84 36.56 66.32 54.32 66.32 54.32 9.68 36.56 9.68 36.56 38.64" />
          <path d="M93.94,23.44A24.69,24.69,0,0,0,82,20.64a25.18,25.18,0,0,0-12,2.8,20.27,20.27,0,0,0-8.16,8.08A24.89,24.89,0,0,0,59,43.76a25.34,25.34,0,0,0,2.88,12.32,20.39,20.39,0,0,0,8.16,8,25.18,25.18,0,0,0,12,2.8,23.93,23.93,0,0,0,10.24-2.16,21.68,21.68,0,0,0,7.76-6,19.61,19.61,0,0,0,4.08-8.64H85.3a4.32,4.32,0,0,1-4.16,2.64,4.74,4.74,0,0,1-3.36-1.2q-1.27-1.27-1.52-4.64h28.4a16.38,16.38,0,0,0,.4-3.52,23.64,23.64,0,0,0-3-12.08A19.29,19.29,0,0,0,93.94,23.44ZM76.42,39.6q.72-5,5.6-5a5.24,5.24,0,0,1,3.6,1.28,4.62,4.62,0,0,1,1.44,3.76Z" />
          <path d="M125.4,2.56A10.47,10.47,0,0,0,118,0a10.59,10.59,0,0,0-7.44,2.56,8.05,8.05,0,0,0-2.8,6.32,8,8,0,0,0,2.8,6.24A10.84,10.84,0,0,0,118,17.6a10.71,10.71,0,0,0,7.36-2.48,8,8,0,0,0,2.8-6.24A8.05,8.05,0,0,0,125.4,2.56Z" />
          <rect x="109.08" y="21.2" width="17.76" height="45.12" />
          <path d="M162,27.36a12,12,0,0,0-4.88-4.88,15,15,0,0,0-7.6-1.84,16.88,16.88,0,0,0-9.52,2.8,18.58,18.58,0,0,0-6.72,8,29.18,29.18,0,0,0-2.4,12.32,29.72,29.72,0,0,0,2.4,12.4,19.24,19.24,0,0,0,6.72,8,17.29,17.29,0,0,0,9.52,2.72,15.58,15.58,0,0,0,7.6-1.76,12.51,12.51,0,0,0,4.88-5v5.36c0,2.93-.58,4.93-1.76,6a7,7,0,0,1-4.88,1.6q-5,0-5.76-3.52H132.14a19.31,19.31,0,0,0,7.2,13.76q6.41,5.11,17.28,5.12,8.08,0,13.2-3.12A18.23,18.23,0,0,0,177.34,77a27.93,27.93,0,0,0,2.32-11.52V21.2H162Zm-1.84,22a6.57,6.57,0,0,1-9.44,0,8,8,0,0,1-1.84-5.6,8,8,0,0,1,1.84-5.6,6.57,6.57,0,0,1,9.44,0,7.94,7.94,0,0,1,1.84,5.6A7.94,7.94,0,0,1,160.14,49.36Z" />
          <path d="M217.64,20.88a16.58,16.58,0,0,0-8.32,2,16,16,0,0,0-5.52,5.2v-21H186v59.2H203.8V42.4a7.2,7.2,0,0,1,1.68-5,5.83,5.83,0,0,1,4.64-1.92,5.75,5.75,0,0,1,4.56,1.92,7,7,0,0,1,1.76,5V66.32h17.68V40.16q0-8.88-4.4-14.08T217.64,20.88Z" />
          <path d="M279.9,23.44a16.88,16.88,0,0,0-9.52-2.8,15,15,0,0,0-7.6,1.84,12.06,12.06,0,0,0-4.8,4.88V7.12H240.22v59.2H258V60.16a12.64,12.64,0,0,0,4.8,5,15.58,15.58,0,0,0,7.6,1.76,17.29,17.29,0,0,0,9.52-2.72,18.66,18.66,0,0,0,6.64-8A28.94,28.94,0,0,0,289,43.76a28.42,28.42,0,0,0-2.48-12.32A18,18,0,0,0,279.9,23.44ZM269.18,49.36a6.57,6.57,0,0,1-9.44,0,7.94,7.94,0,0,1-1.84-5.6,7.94,7.94,0,0,1,1.84-5.6,6.57,6.57,0,0,1,9.44,0,7.94,7.94,0,0,1,1.84,5.6A7.94,7.94,0,0,1,269.18,49.36Z" />
          <path d="M328.67,23.44a28.15,28.15,0,0,0-24.48,0,20.89,20.89,0,0,0-8.56,8.08,23.9,23.9,0,0,0-3,12.24,24.33,24.33,0,0,0,3,12.32,21,21,0,0,0,8.56,8,28.15,28.15,0,0,0,24.48,0,20.39,20.39,0,0,0,8.48-8,23.77,23.77,0,0,0,3.12-12.32,23.35,23.35,0,0,0-3.12-12.24A20.27,20.27,0,0,0,328.67,23.44ZM320.59,49.6a5.3,5.3,0,0,1-4.16,1.92,5.4,5.4,0,0,1-4.24-1.92,9.22,9.22,0,0,1-1.6-5.84q0-3.84,1.68-5.76a5.33,5.33,0,0,1,8.32,0q1.68,1.92,1.68,5.76A8.86,8.86,0,0,1,320.59,49.6Z" />
          <path d="M368.65,23.28A21.31,21.31,0,0,0,362,29.52V21.2H344.25V66.32H362V47.84c0-3,.78-5.07,2.32-6.24s4.08-1.76,7.44-1.76h5v-19A15.44,15.44,0,0,0,368.65,23.28Z" />
        </g>
        <g style={{ fill: "var(--color-cobalt)" }}>
          <path d="M461.8,34.8a62.4,62.4,0,0,0-9-3.84,47.55,47.55,0,0,1-6.4-2.64,3.51,3.51,0,0,1-2-3,2.54,2.54,0,0,1,.72-2,2.49,2.49,0,0,1,1.84-.72c2.19,0,3.41,1.49,3.68,4.48H469.8q-.56-8.88-6.56-13.6T447.08,8.72a29.57,29.57,0,0,0-11.12,2,17.74,17.74,0,0,0-7.76,5.92A15.43,15.43,0,0,0,425.4,26,15.44,15.44,0,0,0,428,35.36a18,18,0,0,0,6.16,5.44,62.85,62.85,0,0,0,9,3.6,35.44,35.44,0,0,1,6.56,2.72,3.44,3.44,0,0,1,2.08,3.12,2.52,2.52,0,0,1-.88,2.08,3.1,3.1,0,0,1-2.16.72q-3.92,0-4.32-4.88H425.56q.56,9.2,7.12,14t16.8,4.72a26.89,26.89,0,0,0,11.44-2.24,17.21,17.21,0,0,0,7.28-6.24,15.72,15.72,0,0,0,2.56-8.8A15,15,0,0,0,468,40.32,17.53,17.53,0,0,0,461.8,34.8Z" />
          <path d="M507,20.88a16.61,16.61,0,0,0-8.32,2,16.21,16.21,0,0,0-5.52,5.2v-21H475.4v59.2h17.76V42.4a7.2,7.2,0,0,1,1.68-5,5.85,5.85,0,0,1,4.64-1.92A5.75,5.75,0,0,1,504,37.36a7,7,0,0,1,1.76,5V66.32h17.68V40.16q0-8.88-4.4-14.08T507,20.88Z" />
          <path d="M558.46,27.36a12,12,0,0,0-4.88-4.88,15,15,0,0,0-7.6-1.84,16.88,16.88,0,0,0-9.52,2.8,18.51,18.51,0,0,0-6.72,8,29.18,29.18,0,0,0-2.4,12.32,29.72,29.72,0,0,0,2.4,12.4,19.17,19.17,0,0,0,6.72,8A17.29,17.29,0,0,0,546,66.88a15.58,15.58,0,0,0,7.6-1.76,12.51,12.51,0,0,0,4.88-5v6.16h17.68V21.2H558.46Zm-1.84,22a6.57,6.57,0,0,1-9.44,0,7.94,7.94,0,0,1-1.84-5.6,7.94,7.94,0,0,1,1.84-5.6,6.57,6.57,0,0,1,9.44,0,7.94,7.94,0,0,1,1.84,5.6A7.94,7.94,0,0,1,556.62,49.36Z" />
          <path d="M598.68,29.52V21.2H580.92V66.32h17.76V47.84q0-4.48,2.32-6.24t7.44-1.76h5v-19a15.39,15.39,0,0,0-8.16,2.4A21.1,21.1,0,0,0,598.68,29.52Z" />
          <path d="M661.94,43.36a23.74,23.74,0,0,0-3-12.08,19.29,19.29,0,0,0-8.16-7.84,24.72,24.72,0,0,0-11.92-2.8,25.18,25.18,0,0,0-12,2.8,20.15,20.15,0,0,0-8.16,8.08,24.89,24.89,0,0,0-2.88,12.24,25.34,25.34,0,0,0,2.88,12.32,20.26,20.26,0,0,0,8.16,8,25.18,25.18,0,0,0,12,2.8,24,24,0,0,0,10.24-2.16,21.77,21.77,0,0,0,7.76-6A19.61,19.61,0,0,0,661,50.08h-18.8A4.32,4.32,0,0,1,638,52.72a4.7,4.7,0,0,1-3.36-1.2q-1.27-1.27-1.52-4.64h28.4A16.38,16.38,0,0,0,661.94,43.36ZM633.3,39.6q.72-5,5.6-5a5.24,5.24,0,0,1,3.6,1.28,4.62,4.62,0,0,1,1.44,3.76Z" />
          <path d="M408.34,31.32c-4.49,0-7.34,6.18-7.34,6.18s-2.84-6.18-7.34-6.18a6.48,6.48,0,0,0-6.66,6.5c0,4.14,1.42,6.08,3.89,9.2,3.07,4,10.11,10.3,10.11,10.3s7-6.25,10.11-10.3c2.47-3.12,3.89-5.15,3.89-9.2A6.39,6.39,0,0,0,408.34,31.32Z" />
        </g>
      </svg>
    )}
  </>
)

Logo.defaultProps = {
  size: "full",
}

Logo.propTypes = {
  size: PropTypes.oneOf(["full", "minimal"]).isRequired,
}

export default Logo
