import React, { useState } from "react"
import ButtonCustom from "../../ButtonCustom"
import Icon from "../../Icon"
import { Checkbox, Form, Input } from "antd"
import styles from "../styles.module.scss"
import Loading from "../../Loading"
import { getTransactionFees } from "../../../../functions/utils/calc"
import { CreditCardSvg, GiftCardSvg } from "../icons"
import { checkGiftCardBalance } from "../../../api"

export const DonateContent = ({
  stage,
  setStage,
  handleCheckoutButtonClick,
  handleGiftRedeemButtonClick,
  totalPledged,
  giftRedeemResult,
  setGiftRedeemResult,
  coverFeesToggled,
  toggleCoverFees,
}) => {
  const [paying, setPaying] = useState(false)
  const [subscribeMail, setSubscribeMail] = useState(true)
  const [additionalPaymentNeeded, setAdditionalPaymentNeeded] = useState(null)

  const handleCheckout = giftCard => {
    if (!paying) {
      handleCheckoutButtonClick({}, subscribeMail, giftCard?.code)
      setPaying(true)
    }
  }

  const handleGiftRedeem = () => {
    setStage(stage + 1)
    setGiftRedeemResult("")
    setAdditionalPaymentNeeded(null)
  }

  const onFinishGiftRedeem = async values => {
    if (!paying) {
      setPaying(true)

      let balanceResult = await checkGiftCardBalance(values.giftCardCode)
      setGiftRedeemResult(balanceResult.result)

      if (balanceResult.result === "success") {
        if (balanceResult.balance >= totalPledged) {
          handleGiftRedeemButtonClick(
            {},
            subscribeMail,
            values.email,
            values.giftCardCode
          )
        } else {
          setAdditionalPaymentNeeded({
            code: values.giftCardCode,
            balance: balanceResult.balance,
          })
        }
      }
    }

    if (giftRedeemResult !== "success") {
      setPaying(false)
    }
  }

  switch (stage) {
    case 0:
      return (
        <>
          <div className={styles.header}>
            <Icon
              icon="unclassified"
              aesthetic="simple"
              className={styles.icon}
            />
            <h3 className={`${styles.heading} ${styles.holidayHeading}`}>
              Thank you for supporting a neighbor in need!
            </h3>
          </div>
          <div className={styles.actions}>
            <div className={styles.applyDonation}>
              <div className={styles.buttonContainer}>
                <div
                  className={styles.payWithCard}
                  onClick={() => handleCheckout()}
                >
                  <CreditCardSvg />
                  {paying ? (
                    <Loading />
                  ) : (
                    <p className={styles.payWithCardText}>Pay with card</p>
                  )}
                </div>
                <div className={styles.payWithCard} onClick={handleGiftRedeem}>
                  <GiftCardSvg />
                  <p className={styles.payWithCardText}>Pay with gift card</p>
                </div>
              </div>
              <div className={styles.checkboxes}>
                <div className={`${styles.text} ${styles.further}`}>
                  Help your gift go further!
                </div>
                <Checkbox
                  checked={coverFeesToggled}
                  onClick={() => toggleCoverFees(!coverFeesToggled)}
                >
                  If paying with Credit/Debit Card, cover transaction fee
                  {!totalPledged || totalPledged === 0
                    ? ""
                    : ` ($${getTransactionFees(totalPledged).toFixed(2)})`}
                </Checkbox>
                <Checkbox
                  className={styles.subscribe}
                  checked={subscribeMail}
                  onClick={() => setSubscribeMail(!subscribeMail)}
                >
                  Stay updated by subscribing to NeighborShare news
                </Checkbox>
              </div>
            </div>
          </div>
        </>
      )
    case 1:
      return (
        <>
          <div className={styles.header}>
            <div className={styles.headerSubtitlePayGift}>
              Pay with Gift Card
            </div>
            <div className={styles.dollarMonthlyDonation}>
              <div className={styles.dollarText}>
                {additionalPaymentNeeded
                  ? `\$${(
                      totalPledged - additionalPaymentNeeded.balance
                    ).toFixed(2)}`
                  : `\$${totalPledged.toFixed(2)}`}
              </div>
            </div>
            <div
              className={`${styles.headerSubtitlePayGift} ${styles.headerCustomSize}`}
            >
              Your Cart Balance
            </div>
          </div>
          <div className={styles.donationFormWrapperGift}>
            <Form onFinish={onFinishGiftRedeem}>
              <div className={styles.actions}>
                <div className={styles.inputDetails}>
                  {!additionalPaymentNeeded ? (
                    <>
                      <Form.Item
                        className={styles.inputForm}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                            validateTrigger: "onSubmit",
                          },
                          {
                            required: true,
                            message: "Please enter your email address",
                          },
                        ]}
                      >
                        <Input
                          name="email"
                          placeholder={"Your email address"}
                          className={styles.inputFrame}
                          disabled={!!additionalPaymentNeeded}
                        />
                      </Form.Item>
                      <Form.Item
                        className={styles.inputForm}
                        name="giftCardCode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a gift card code",
                            validateTrigger: "onSubmit",
                          },
                        ]}
                      >
                        <Input
                          name="giftCardCode"
                          placeholder={"Enter your gift card code"}
                          className={styles.inputFrame}
                          disabled={!!additionalPaymentNeeded}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Input
                      name="giftCardApplied"
                      className={`${styles.inputFrame} ${styles.inputForm} ${styles.inputFrameApplied}`}
                      readOnly={true}
                      value={`\$${additionalPaymentNeeded.balance} Gift Card Applied!`}
                    />
                  )}

                  {giftRedeemResult && giftRedeemResult !== "success" ? (
                    <div className={styles.giftError}>
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{giftRedeemResult}</div>
                      </div>
                      <p>
                        or
                        <br />
                        email support@nbshare.org for assistance
                      </p>
                    </div>
                  ) : null}
                  {additionalPaymentNeeded && (
                    <div className={styles.text}>
                      You will now be taken to checkout to complete the
                      remainder of the transaction.
                    </div>
                  )}
                </div>
                {!additionalPaymentNeeded ? (
                  <div className={styles.applyDonation}>
                    <Form.Item>
                      <ButtonCustom
                        className={styles.checkoutButton}
                        iconClassName={styles.checkoutButtonIcon}
                        size="medium"
                        disabled={paying}
                      >
                        {paying ? <Loading /> : "Apply gift card"}
                      </ButtonCustom>
                    </Form.Item>
                  </div>
                ) : (
                  <div className={styles.applyDonation}>
                    <Form.Item>
                      <ButtonCustom
                        className={styles.checkoutButton}
                        iconClassName={styles.checkoutButtonIcon}
                        size="medium"
                        onClick={() => handleCheckout(additionalPaymentNeeded)}
                      >
                        {paying ? <Loading /> : "Continue to payment"}
                      </ButtonCustom>
                    </Form.Item>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </>
      )
  }
}
