import { makeGiftCardPurchase } from "../api"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"

const stripePublicKey = process.env.GATSBY_STRIPE_PUBLIC_KEY

const redirectToGiftCardCheckout = async (
  value,
  subscribeMail,
  senderName,
  recipientName,
  deliveryEmail,
  isCoveringFees
) => {
  if (!value || !senderName || !recipientName || !deliveryEmail) {
    // TODO: expose and handle bad data error
    console.warn(`invalid checkout`)
  }

  const sessionId = await makeGiftCardPurchase(
    value,
    subscribeMail,
    senderName,
    recipientName,
    deliveryEmail,
    isCoveringFees
  )

  const stripe = await loadStripe(stripePublicKey)
  const { error } = await stripe.redirectToCheckout({
    sessionId,
  })

  if (error) {
    await navigate("/error-page")
  }
}

export default redirectToGiftCardCheckout
