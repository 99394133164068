// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-auth-0-src-pages-auth-callback-tsx": () => import("./../../../node_modules/gatsby-theme-auth0/src/pages/auth/callback.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-auth-0-src-pages-auth-callback-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-bulk-order-js": () => import("./../../../src/pages/bulk-order.js" /* webpackChunkName: "component---src-pages-bulk-order-js" */),
  "component---src-pages-error-page-js": () => import("./../../../src/pages/error-page.js" /* webpackChunkName: "component---src-pages-error-page-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-general-fund-success-js": () => import("./../../../src/pages/general-fund-success.js" /* webpackChunkName: "component---src-pages-general-fund-success-js" */),
  "component---src-pages-gift-card-bulk-success-js": () => import("./../../../src/pages/gift-card-bulk-success.js" /* webpackChunkName: "component---src-pages-gift-card-bulk-success-js" */),
  "component---src-pages-gift-card-success-js": () => import("./../../../src/pages/gift-card-success.js" /* webpackChunkName: "component---src-pages-gift-card-success-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landingpage-js": () => import("./../../../src/pages/landingpage.js" /* webpackChunkName: "component---src-pages-landingpage-js" */),
  "component---src-pages-manage-subscription-js": () => import("./../../../src/pages/manage-subscription.js" /* webpackChunkName: "component---src-pages-manage-subscription-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-role-js": () => import("./../../../src/pages/role.js" /* webpackChunkName: "component---src-pages-role-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-success-gift-js": () => import("./../../../src/pages/success-gift.js" /* webpackChunkName: "component---src-pages-success-gift-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-family-js": () => import("./../../../src/templates/family.js" /* webpackChunkName: "component---src-templates-family-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

