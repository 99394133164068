import { gql } from "@apollo/client"

export const GET_SPONSOR_INFO_BY_NAME = gql`
  query GetSponsorInfoByName($corpName: String!) {
    sponsors: sponsorsByName(name: $corpName) {
      name
      logo
      locations
      needs {
        display_name
        need_type
        needs_aggregate(where: { is_available: { _eq: true } }) {
          aggregate {
            count(distinct: true, columns: family_id)
          }
        }
      }
    }
  }
`
