export const siteMap = [
  {
    label: "About Us",
    url: "/about-us",
    scope: "internal",
  },
  {
    label: "FAQs",
    url: "/faqs",
    scope: "internal",
  },
  {
    label: "Our Team",
    url: "/team",
    scope: "internal",
  },
  {
    label: "Blog",
    url: "https://medium.com/neighborshare",
    scope: "external",
  },
  {
    label: "Service Partners",
    url: "/#service-partners",
    scope: "internal",
  },
]
