import React from "react"
import styles from "./styles.module.scss"

const IconButton = ({ icon, onClick, buttonProps }) => (
  <div className={styles.iconButton} onClick={onClick} {...buttonProps}>
    {icon}
  </div>
)

export default IconButton
