import React from "react"

const IconSimple = () => {
  return (
    <>
      <symbol id="simple-search" viewBox="0 0 75 75">
        <g>
          <path
            d="M33.75,44.82a63.63,63.63,0,0,0,6.5-6.33c1.59-1.92,2.5-3.17,2.5-5.67a4,4,0,0,0-4.28-4c-2.89,0-4.72,4.42-4.72,4.42S31.92,28.82,29,28.82a4.09,4.09,0,0,0-4.28,4c0,2.55.91,3.75,2.5,5.67A63.63,63.63,0,0,0,33.75,44.82Z"
            style={{ fill: "#ff7b51" }}
          />
          <path
            d="M62.92,59.43,51.42,49.08l-.09,0a22,22,0,1,0-1.26,1.53h0l11.5,10.35a1,1,0,0,0,.67.26,1,1,0,0,0,.67-1.75ZM33.75,55.82a20,20,0,1,1,20-20A20,20,0,0,1,33.75,55.82Z"
            style={{ fill: "#04063e" }}
          />
        </g>
      </symbol>

      <symbol id="simple-housing" viewBox="0 0 75 75">
        <g>
          <path
            d="M37.5,9,11.38,30.21V66H63.62V30.21Z"
            style={{ fill: "#ff7b51" }}
          />
          <g>
            <path
              d="M37.5,53.65a80.69,80.69,0,0,0,8.57-7.9c2.1-2.4,3.31-4,3.31-7.06,0-2.66-2.16-5-5.66-5-3.81,0-6.22,5.51-6.22,5.51s-2.41-5.51-6.22-5.51c-3.43,0-5.66,2.4-5.66,5,0,3.17,1.21,4.66,3.31,7.06A80.69,80.69,0,0,0,37.5,53.65Z"
              style={{ fill: "#ffffff" }}
            />
            <path d="M37.5,54.65a1,1,0,0,1-.63-.22,82,82,0,0,1-8.71-8c-2.19-2.5-3.54-4.22-3.54-7.7,0-2.95,2.49-6,6.66-6,2.94,0,5.09,2.61,6.22,4.42,1.13-1.81,3.28-4.42,6.22-4.42,3.8,0,6.65,2.57,6.65,6S49,43.88,46.83,46.41a82.53,82.53,0,0,1-8.7,8A1,1,0,0,1,37.5,54.65ZM31.28,34.7c-3,0-4.66,2-4.66,4,0,2.78,1,4,3.06,6.4a72.66,72.66,0,0,0,7.82,7.27,74.36,74.36,0,0,0,7.81-7.25c2-2.32,3.06-3.64,3.06-6.42,0-2-1.59-4-4.65-4-2.57,0-4.7,3.54-5.31,4.91a1,1,0,0,1-.91.59,1,1,0,0,1-.92-.59C36,38.24,33.85,34.7,31.28,34.7Z" />
          </g>
        </g>
      </symbol>

      <symbol id="simple-money" viewBox="0 0 75 75">
        <g>
          <path
            d="M73.5,11.77A1.85,1.85,0,1,0,70,13a3,3,0,0,1,0,2.68,2.58,2.58,0,0,1-1,.77,5.16,5.16,0,0,0-3.49-5,5.45,5.45,0,0,0-1.78-.3A5,5,0,0,0,59,14.42a5.07,5.07,0,0,0,3.22,6.39,5.37,5.37,0,0,0,.94.23,16.41,16.41,0,0,1-2.69,1.9c-5.21-5.83-13.49-9.62-22.8-9.62a37.28,37.28,0,0,0-8.08.52A16.51,16.51,0,0,0,16.48,7.57,17,17,0,0,0,9.51,9a1.85,1.85,0,0,0-.07,3.35c.08,0,7.16,3.7,7.17,8.67-.71.74-2,2.15-2.89,3.28a.64.64,0,0,0-.1.13l-.25.33c-.95,1.24-4.85,4.83-8.29,4.83A4.08,4.08,0,0,0,1,33.71V42.6a4.08,4.08,0,0,0,4.08,4.08c4.18,0,8.62,3.54,9.12,4a25.62,25.62,0,0,1,2.49,2.86c2.07,7.6,2.18,8.24,2.74,11.64l.13.74a1.84,1.84,0,0,0,1.82,1.55h6.67a1.85,1.85,0,0,0,1.84-1.63c.21-1.73.53-3.76.82-5a33.85,33.85,0,0,0,14,0c.29,1.27.61,3.3.82,5a1.85,1.85,0,0,0,1.84,1.63H54a1.87,1.87,0,0,0,1.83-1.55c.61-3.67,2.29-10.2,2.78-12.09a22,22,0,0,0,7.62-16.38,21,21,0,0,0-3.47-11.49,14.49,14.49,0,0,0,5.53-5.38A7.49,7.49,0,0,0,73,17.78C73.8,16.67,74.51,14.73,73.5,11.77Zm-11,3.88a1.29,1.29,0,0,1,1.24-.79,1.52,1.52,0,0,1,.55.1,1.36,1.36,0,0,1,1,1.66,1.39,1.39,0,0,1-1.78.69A1.37,1.37,0,0,1,62.51,15.65Z"
            style={{ fill: "#f1e6d9" }}
          />
          <path
            d="M19.45,32.2a2.51,2.51,0,1,0-2.51-2.51A2.51,2.51,0,0,0,19.45,32.2Z"
            style={{ fill: "#04063e" }}
          />
          <path
            d="M39.41,36.2V24.91a5.68,5.68,0,0,1,4.06,5.44,1,1,0,1,0,2,0,7.68,7.68,0,0,0-6.07-7.51V21a1,1,0,0,0-2,0v1.66A7.67,7.67,0,0,0,37.4,38V49.37a5.67,5.67,0,0,1-5.28-5.66,1,1,0,0,0-2,0,7.68,7.68,0,0,0,7.29,7.67v1.77a1,1,0,1,0,2,0V51.23a7.69,7.69,0,0,0,0-15Zm-7.29-5.85A5.66,5.66,0,0,1,37.4,24.7V36A5.67,5.67,0,0,1,32.12,30.35Zm7.29,18.8V38.27a5.67,5.67,0,0,1,0,10.88Z"
            style={{ fill: "#04063e" }}
          />
        </g>
      </symbol>

      <symbol id="simple-unclassified" viewBox="0 0 75 75">
        <g>
          <path
            d="M38,65.82s10.13-8.76,14.55-14.45c3.56-4.38,5.61-7.22,5.61-12.91,0-4.86-3.67-9.12-9.6-9.12C42.08,29.34,38,39.41,38,39.41s-4.1-10.07-10.57-10.07a9.22,9.22,0,0,0-9.59,9.12c0,5.8,2.05,8.53,5.61,12.91C27.85,57.06,38,65.82,38,65.82Z"
            style={{ fill: "#ff7b51" }}
          />
          <path
            d="M57.08,23.07a1,1,0,0,1-.58-.19,1,1,0,0,1-.19-1.35l5.16-6.82A1,1,0,0,1,63,15.87l-5.15,6.82A1,1,0,0,1,57.08,23.07Z"
            style={{ fill: "#04063e" }}
          />
          <path
            d="M67.39,31.32a1,1,0,0,1-.89-.6A1,1,0,0,1,67,29.47l5.15-2.09a1,1,0,0,1,.72,1.78l-5.15,2.09A.92.92,0,0,1,67.39,31.32Z"
            style={{ fill: "#04063e" }}
          />
          <path
            d="M39.56,21.11a1,1,0,0,1-1-1v-10a1,1,0,0,1,1.92,0v10A1,1,0,0,1,39.56,21.11Z"
            style={{ fill: "#04063e" }}
          />
          <path
            d="M22,21a1,1,0,0,1-.71-.32L16.17,14.9a1,1,0,0,1,1.44-1.28l5.15,5.77a1,1,0,0,1-.08,1.35A1,1,0,0,1,22,21Z"
            style={{ fill: "#04063e" }}
          />
          <path
            d="M10.71,31.29a1,1,0,0,1-.24,0L2.23,29.2a1,1,0,0,1,.46-1.86l8.25,2.06a1,1,0,0,1,.7,1.16A1,1,0,0,1,10.71,31.29Z"
            style={{ fill: "#04063e" }}
          />
        </g>
      </symbol>

      <symbol id="simple-location" viewBox="0 0 24 24">
        <path d="M12 0C7.802 0 4 3.403 4 7.602 4 11.8 7.469 16.812 12 24c4.531-7.188 8-12.2 8-16.398C20 3.403 16.199 0 12 0zm0 11a3 3 0 110-6 3 3 0 010 6z" />
      </symbol>

      <symbol
        id="simple-person"
        width={200}
        height={200}
        viewBox="0 0 200 200"
        fill="none"
      >
        <circle cx={87.5} cy={87.5} r={87.5} fill="#C9AFD5" />
        <path
          d="M120.648 119.583c0-18.416-14.929-33.345-33.345-33.345-18.416 0-33.345 14.929-33.345 33.345"
          stroke="#04063E"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <circle
          cx={87.337}
          cy={75.124}
          r={23.082}
          fill="#fff"
          stroke="#04063E"
          strokeWidth={2}
        />
        <path
          d="M94.559 77.385a2.149 2.149 0 100-4.298 2.149 2.149 0 000 4.298zM81.661 77.385a2.149 2.149 0 100-4.298 2.149 2.149 0 000 4.298z"
          fill="#04063E"
        />
        <path
          d="M91.64 86.222c-.519 1.207-1.724 2.413-3.852 2.413s-3.45-1.206-3.851-2.413"
          stroke="#04063E"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </symbol>
    </>
  )
}

export default IconSimple
