import React from "react"

const IconsMinimal = () => {
  return (
    <>
      <symbol id="minimal-housing" viewBox="0 0 27 27">
        <g>
          <path d="M23.12,10.5,13.89,3a.63.63,0,0,0-.78,0L3.88,10.5a.59.59,0,0,0-.23.48V23.5a.61.61,0,0,0,.62.62H22.73a.63.63,0,0,0,.63-.62V10.94A.65.65,0,0,0,23.12,10.5Zm-1,12.42H4.89V11.24L13.5,4.3l8.61,6.94Z" />
          <path d="M11.35,11.5a2.47,2.47,0,0,0-2.58,2.36v0a4.07,4.07,0,0,0,1.28,2.93,27.32,27.32,0,0,0,3,2.94.63.63,0,0,0,.4.14.67.67,0,0,0,.4-.14,29.62,29.62,0,0,0,3-2.93,4.13,4.13,0,0,0,1.37-2.94,2.45,2.45,0,0,0-2.52-2.38h-.1a2.76,2.76,0,0,0-2.15,1.35A2.79,2.79,0,0,0,11.35,11.5Zm2.72,2.85c.18-.41.85-1.6,1.58-1.6A1.21,1.21,0,0,1,17,13.82a.34.34,0,0,0,0,.1,3,3,0,0,1-1,2.15,23.4,23.4,0,0,1-2.47,2.41A21.81,21.81,0,0,1,11,16.06a2.85,2.85,0,0,1-1-2.14,1.22,1.22,0,0,1,1.28-1.17h0c.73,0,1.4,1.19,1.57,1.6a.65.65,0,0,0,.89.23.72.72,0,0,0,.25-.27Z" />
        </g>
      </symbol>

      <symbol id="minimal-unclassified" viewBox="0 0 27 27">
        <g>
          <path d="M17.48,9.75c-1.79,0-3.09,1.68-3.79,2.86-.7-1.18-2-2.86-3.79-2.86a4,4,0,0,0-4.08,3.9c0,2.27.82,3.39,2.15,5a50.91,50.91,0,0,0,5.31,5.28.66.66,0,0,0,.82,0,52.73,52.73,0,0,0,5.3-5.27c1.34-1.65,2.16-2.81,2.16-5a3.91,3.91,0,0,0-3.89-3.92Zm1,8.16a48.2,48.2,0,0,1-4.74,4.74A46.21,46.21,0,0,1,9,17.9c-1.31-1.56-1.92-2.42-1.92-4.25A2.68,2.68,0,0,1,9.78,11h.13c1.55,0,2.84,2.33,3.21,3.23a.65.65,0,0,0,.87.28.71.71,0,0,0,.29-.28c.37-.9,1.66-3.23,3.21-3.23a2.64,2.64,0,0,1,2.82,2.46c0,.06,0,.12,0,.19C20.32,15.48,19.67,16.38,18.48,17.91Z" />
          <path d="M21.91,5,20.06,7.4a.63.63,0,0,0,.12.88.72.72,0,0,0,.38.13.66.66,0,0,0,.5-.25l1.85-2.45a.63.63,0,0,0-.05-.89.62.62,0,0,0-.89,0A.16.16,0,0,0,21.91,5Z" />
          <path d="M26.69,9.76a.62.62,0,0,0-.81-.35L24,10.16a.63.63,0,0,0-.34.82.65.65,0,0,0,.57.39l.22,0,1.85-.75a.62.62,0,0,0,.36-.8Z" />
          <path d="M14.27,7.7a.62.62,0,0,0,.62-.62V3.48a.64.64,0,0,0-.71-.55.63.63,0,0,0-.54.55v3.6A.63.63,0,0,0,14.27,7.7Z" />
          <path d="M8,7.66a.73.73,0,0,0,.41-.18.64.64,0,0,0,.09-.87L6.59,4.54a.63.63,0,0,0-.88-.14.62.62,0,0,0-.14.88.41.41,0,0,0,.09.1l1.82,2.1A.61.61,0,0,0,8,7.66Z" />
          <path d="M4.05,10.13l-3-.74a.61.61,0,0,0-.76.45.63.63,0,0,0,.46.76l3,.74H3.9a.65.65,0,0,0,.58-.46A.62.62,0,0,0,4.05,10.13Z" />
        </g>
      </symbol>

      <symbol id="minimal-childcare" viewBox="0 0 27 27">
        <g>
          <path
            className="a"
            d="M13.51,3.8a9.7,9.7,0,1,0,9.69,9.71v0A9.7,9.7,0,0,0,13.51,3.8Zm0,18.14A8.45,8.45,0,1,1,22,13.48h0A8.46,8.46,0,0,1,13.51,21.94Z"
          />
          <circle className="a" cx="16.42" cy="13.73" r="1" />
          <circle className="a" cx="11.56" cy="13.73" r="1" />
          <path
            className="a"
            d="M15.37,17.1a.63.63,0,0,0-.82.32h0a.88.88,0,0,1-.88.53.83.83,0,0,1-.87-.48.63.63,0,1,0-1.23.26.65.65,0,0,0,0,.13,2.08,2.08,0,0,0,2,1.34,2.12,2.12,0,0,0,2-1.29A.62.62,0,0,0,15.37,17.1Z"
          />
          <path
            className="a"
            d="M14.8,12.08A.63.63,0,0,0,15,10.83H14.8a2.14,2.14,0,0,1-2.18-2.1V8.6a.63.63,0,0,0-1.25,0,3.34,3.34,0,0,0,3.21,3.47Z"
          />
        </g>
      </symbol>

      <symbol id="minimal-groceries" viewBox="0 0 27 27">
        <g>
          <path d="M2.7,12.75v.66A11,11,0,0,0,13.51,24.09,11,11,0,0,0,24.3,13.41v-.66ZM13.51,22.84A9.76,9.76,0,0,1,4,14h18.9A9.75,9.75,0,0,1,13.51,22.84Z" />
          <path d="M9.94,8.57a2,2,0,0,0-.88,1.59.64.64,0,0,0,.55.7.62.62,0,0,0,.7-.55.38.38,0,0,0,0-.15c0-.25.11-.36.44-.64a2,2,0,0,0,.88-1.59,2,2,0,0,0-.88-1.59c-.33-.28-.44-.39-.44-.64s.11-.36.44-.64a2,2,0,0,0,.92-1.59.62.62,0,0,0-.7-.55.65.65,0,0,0-.55.55c0,.25-.11.36-.44.64A2,2,0,0,0,9.1,5.7a2,2,0,0,0,.84,1.59c.33.28.44.39.44.64S10.31,8.29,9.94,8.57Z" />
          <path d="M13.1,8.57a2,2,0,0,0-.88,1.59.64.64,0,0,0,.55.7.62.62,0,0,0,.7-.55.38.38,0,0,0,0-.15c0-.25.11-.36.44-.64a2,2,0,0,0,.87-1.59,2,2,0,0,0-.84-1.59c-.33-.28-.43-.39-.43-.64s.1-.36.43-.64a2,2,0,0,0,.84-1.59.62.62,0,0,0-.7-.55.65.65,0,0,0-.55.55c0,.25-.1.36-.43.64a2,2,0,0,0-.88,1.59,2,2,0,0,0,.88,1.59c.33.28.43.39.43.64S13.43,8.29,13.1,8.57Z" />
          <path d="M16.06,8.57a2,2,0,0,0-.88,1.59.64.64,0,0,0,.55.7.62.62,0,0,0,.7-.55.38.38,0,0,0,0-.15c0-.25.11-.36.43-.64a2,2,0,0,0,.89-1.59,2,2,0,0,0-.89-1.59c-.31-.28-.43-.39-.43-.64s.11-.36.43-.64a2,2,0,0,0,.89-1.59A.64.64,0,0,0,17,2.92a.63.63,0,0,0-.54.55c0,.25-.11.36-.44.64a2,2,0,0,0-.88,1.59,2,2,0,0,0,.88,1.59c.32.28.44.39.44.64S16.38,8.29,16.06,8.57Z" />
        </g>
      </symbol>

      <symbol id="minimal-transport" viewBox="0 0 27 27">
        <path d="M20.78,11.3c-.29-2.79-3.09-4.64-7.09-4.64C10,6.66,6.91,9,6.6,12c-3.38.06-6.1,2.5-6.1,5.51a.52.52,0,0,0,.52.52H1a.53.53,0,0,0,.36-.17H3.86a2.66,2.66,0,0,0,5.31,0h9a2.67,2.67,0,0,0,5.3,0h2.15a.51.51,0,0,0,.37.16.52.52,0,0,0,.51-.52A6.23,6.23,0,0,0,20.78,11.3ZM6.55,19.35A1.65,1.65,0,1,1,8.2,17.7,1.65,1.65,0,0,1,6.55,19.35Zm14.28,0a1.65,1.65,0,1,1,1.65-1.65A1.65,1.65,0,0,1,20.83,19.35Zm0-4.33a2.69,2.69,0,0,0-2.53,1.83H9.08a2.66,2.66,0,0,0-5.06,0H1.6A5,5,0,0,1,6.73,13a.54.54,0,0,0,.18,0,.54.54,0,0,0,.18,0,.51.51,0,0,0,.51-.51c0-2.67,2.73-4.84,6.09-4.84,3,0,6.08,1.28,6.08,4.12a.52.52,0,0,0,.52.52,5.21,5.21,0,0,1,5.15,4.52H23.36A2.7,2.7,0,0,0,20.83,15Z" />
      </symbol>

      <symbol id="minimal-bills" viewBox="0 0 27 27">
        <path d="M23,11.93h-2.7a4.15,4.15,0,0,0-4.14,4.13v3.38a3,3,0,0,1-6,0V17.38A5.75,5.75,0,0,0,15,11.7V8.24a.63.63,0,0,0-.63-.63H12.18V4a.62.62,0,0,0-.7-.54.64.64,0,0,0-.55.54V7.61H7.33V4a.62.62,0,0,0-.7-.54A.64.64,0,0,0,6.08,4V7.61h-2a.62.62,0,0,0-.62.62h0v3.5a5.75,5.75,0,0,0,5.4,5.74v2a4.27,4.27,0,0,0,8.53,0V16.06a2.9,2.9,0,0,1,2.89-2.88H23a.63.63,0,0,0,.55-.7A.64.64,0,0,0,23,11.93ZM4.71,11.74V8.86h9v2.88a4.51,4.51,0,1,1-9,.6Q4.7,12,4.71,11.74Z" />
      </symbol>

      <symbol id="minimal-education" viewBox="0 0 27 27">
        <path d="M26.11,10.88a.61.61,0,0,0-.39-.57l-12-4.74a.61.61,0,0,0-.46,0l-12,4.74a.62.62,0,0,0-.35.8.67.67,0,0,0,.35.35l4.2,1.85v5.5a.62.62,0,0,0,.26.51,14.47,14.47,0,0,0,7.77,2.16A13.12,13.12,0,0,0,21,19.3a.62.62,0,0,0,.23-.49V13.42l3.6-1.58V20.6a.63.63,0,0,0,1.25,0V11A.81.81,0,0,0,26.11,10.88ZM20,18.49a12.18,12.18,0,0,1-6.52,1.74,13.49,13.49,0,0,1-6.78-1.75V13.86l6.53,2.86a.53.53,0,0,0,.25,0,1,1,0,0,0,.25,0L20,14Zm-6.52-3L3.11,10.92l10.38-4.1,10.37,4.1Z" />
      </symbol>

      <symbol id="minimal-check" viewBox="0 0 27 27">
        <path d="M10.42,20h0a1,1,0,0,1-.71-.35L4.74,14a1,1,0,0,1,1.52-1.31l4.22,4.9L20.79,7.29a1,1,0,0,1,1.42,1.42l-11.08,11A1,1,0,0,1,10.42,20Z" />
      </symbol>

      <svg id="minimal-chevron-down" width="14" fill="none" viewBox="0 0 9 6">
        <path
          d="M1 1.25L4.5 4.75L8 1.25"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <symbol id="minimal-burger" viewBox="0 0 16 11">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M1 1h14M1 9.27295h14"
        />
      </symbol>

      <svg id="minimal-heart" viewBox="0 0 27 27">
        <path d="M13.5,24.27a83,83,0,0,0,8.38-8.53c2-2.59,3.22-4.27,3.22-7.63a5.29,5.29,0,0,0-5.52-5.38c-3.72,0-6.08,5.11-6.08,5.11S11.14,2.73,7.42,2.73A5.37,5.37,0,0,0,1.9,8.11c0,3.43,1.18,5,3.22,7.63A83,83,0,0,0,13.5,24.27Z" />
      </svg>

      <symbol id="minimal-close" viewBox="0 0 27 27">
        <polygon points="21.94 23 13.5 14.56 5.06 23 4 21.94 12.44 13.5 4 5.06 5.06 4 13.5 12.44 21.94 4 23 5.06 14.56 13.5 23 21.94 21.94 23" />
      </symbol>

      <svg id="minimal-mothers-day" viewBox="0 0 25 25" fill="none">
        <path
          d="M22.5 12.4999H2.49998M22.5 12.4999C23.4205 12.4999 24.1666 11.7537 24.1666 10.8333V9.16659C24.1666 8.24611 23.4205 7.49992 22.5 7.49992H2.49998C1.57951 7.49992 0.833313 8.24611 0.833313 9.16659V10.8333C0.833313 11.7537 1.57951 12.4999 2.49998 12.4999M22.5 12.4999V20.8333C22.5 22.6742 21.0076 24.1666 19.1666 24.1666H5.83331C3.99236 24.1666 2.49998 22.6742 2.49998 20.8333L2.49998 12.4999M12.5 7.49992V5.83325M12.5 7.49992H7.02379C5.44583 7.49992 4.16665 6.22073 4.16665 4.64278V4.16659C4.16665 2.32564 5.65903 0.833252 7.49998 0.833252C10.2614 0.833252 12.5 3.07183 12.5 5.83325M12.5 7.49992H17.9762C19.5541 7.49992 20.8333 6.22073 20.8333 4.64278V4.16659C20.8333 2.32564 19.3409 0.833252 17.5 0.833252C14.7386 0.833252 12.5 3.07183 12.5 5.83325M12.5 7.49992V24.1666"
          stroke="black"
          strokeWidth="1.66667"
        />
      </svg>
    </>
  )
}

export default IconsMinimal
