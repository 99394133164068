import React, { useCallback, useEffect, useState, useRef } from "react"
import ButtonCustom from "../ButtonCustom"
import InstitutionalMenu, { menuItems } from "../InstitutionalMenu"
import LinkCustom from "../LinkCustom"
import Logo from "../Logo"
import CampaignBanner from "../CampaignBanner"
import { navigate } from "gatsby"
// functions
import { handleExploreNeeds, handleGetMatched } from "../../utils"
import { debounce } from "../../utils/debounce"
import { withLocation } from "../../utils/index"

import styles from "./styles.module.scss"
import NewLogo from "../Logo/newLogo"
import Modal, { ModalType } from "../Modal"

const NavItems = ({ handleClick }) => {
  return menuItems.map((item, index) => (
    <div className={styles.navLink} key={`header-${item.label}-${index}`}>
      <LinkCustom
        size="nav"
        btnType={item.label.toLowerCase()}
        url={item.url}
        title={item.label}
        onClick={() => handleClick(item.label)}
      >
        {item.label}
      </LinkCustom>
    </div>
  ))
}

const Header = ({ pathname, search }) => {
  const headerRef = useRef(null)
  const isDynamic = pathname === "/"
  const [isMobile, setIsMobile] = useState(null)
  const [headerVisible, setHeaderVisible] = useState(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { donate } = search

  const [generalFundOpen, setGeneralFundOpen] = useState(false)
  const [giftCardOpen, setGiftCardOpen] = useState(false)

  const toggleMenu = () => setMobileMenuOpen(!mobileMenuOpen)

  const resizeListener = () => setIsMobile(window.innerWidth < 1024)

  const scrollListener = useCallback(() => {
    const header = headerRef && headerRef.current ? headerRef.current : null
    if (!header) return

    const scrollTop = document.scrollingElement.scrollTop
    const innerHeight = window.innerHeight
    let isOverThreshold = scrollTop >= innerHeight / 4
    setHeaderVisible(isOverThreshold)
  }, [setHeaderVisible])

  const debounceScrollListener = debounce(scrollListener, 50)

  const handleNavClick = clicked_button => {
    setMobileMenuOpen(false)
    if (clicked_button === "Donate") {
      setGeneralFundOpen(true)
    }
  }

  const handleDonateClick = () => {
    setMobileMenuOpen(false)
    setGeneralFundOpen(true)
  }

  const handleNeeds = () => {
    setMobileMenuOpen(false)
    handleExploreNeeds()
  }
  const handleMatch = () => {
    setMobileMenuOpen(false)
    handleGetMatched()
  }

  useEffect(() => {
    setIsMobile(window && window.innerWidth < 1024)
    window && window.addEventListener("resize", resizeListener)
    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", debounceScrollListener)

    return () => window.removeEventListener("scroll", debounceScrollListener)
  }, [isDynamic, debounceScrollListener])

  useEffect(() => {
    if (donate) {
      setGeneralFundOpen(true)
    }
  }, [donate, setGeneralFundOpen])

  return (
    <div
      className={`
            ${styles.headerWrapper}
            ${styles.headerSticky}
            ${mobileMenuOpen && styles.headerWrapperOpen}
          `}
      ref={headerRef}
    >
      <CampaignBanner
        showClose={false}
        banner="NeighborShare has giving cards!"
        buttonText="Purchase Now"
        buttonOnPress={() => setGiftCardOpen(true)}
      />
      <Modal
        modalType={ModalType.GiveGift}
        modalOpen={giftCardOpen}
        handleModalClose={() => {
          setGiftCardOpen(false)
        }}
      />
      <header className={styles.header}>
        <Modal
          modalType={ModalType.GeneralFund}
          modalOpen={generalFundOpen}
          handleModalClose={() => {
            setGeneralFundOpen(false)
          }}
        />
        {!isMobile && <NewLogo styles={styles} onClick={() => navigate("/")} />}
        {isMobile && <NavItems handleClick={handleNavClick} />}
        <div
          className={`
              ${styles.mobileHeader}
              ${mobileMenuOpen && styles.mobileHeaderOpen}`}
        >
          {isMobile && (
            <div
              className={`
                ${styles.headerNavBtn}
                ${
                  mobileMenuOpen ? styles.btnIconClose : styles.btnIconHamburger
                }`}
            >
              <svg
                className={
                  mobileMenuOpen ? styles.btnIconClose : styles.btnIconHamburger
                }
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={toggleMenu}
              >
                <line
                  x1="1"
                  y1="7"
                  x2="19"
                  y2="7"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="1"
                  y1="13"
                  x2="19"
                  y2="13"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="19"
                  y2="1"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              {<NewLogo styles={styles} onClick={() => navigate("/")} />}
            </div>
          )}
          {isMobile && !mobileMenuOpen && (
            <button
              className={styles.headerDonateBtn}
              onClick={handleDonateClick}
              title="Donate - Meet Households"
            >
              Donate
            </button>
          )}
        </div>
        {!isMobile && (
          <>
            <NavItems handleClick={handleNavClick} />
          </>
        )}
      </header>
    </div>
  )
}

export default withLocation(Header)
