import { gql } from "@apollo/client"

export const GET_FAMILIES_REGION = hasRegion => {
  if (hasRegion) {
    return gql`
      query allFamiliesByRegion($region_id: uuid, $limit: Int, $offset: Int) {
        families(
          limit: $limit
          offset: $offset
          where: {
            organization: { region: { region_id: { _eq: $region_id } } }
          }
        ) {
          family_id
          display_name
          location_text
          size
          situation
          members
          organization {
            organization_id
            name
            region {
              name
            }
            hidden
          }
          needs_aggregate(where: { is_available: { _eq: true } }) {
            aggregate {
              sum {
                value
              }
            }
          }
          needs {
            need_id
            need_type
            needTypeByNeedType {
              display_name
            }
            is_available
            value
          }
        }
      }
    `
  } else {
    return gql`
      query allFamiliesByRegion($limit: Int, $offset: Int) {
        families(
          limit: $limit
          offset: $offset
          where: { needs: { is_available: { _eq: true } } }
        ) {
          family_id
          display_name
          location_text
          size
          situation
          members
          organization {
            organization_id
            name
            region {
              name
            }
            hidden
          }
          needs_aggregate(where: { is_available: { _eq: true } }) {
            aggregate {
              sum {
                value
              }
            }
          }
          needs {
            need_id
            need_type
            needTypeByNeedType {
              display_name
            }
            is_available
            value
          }
        }
      }
    `
  }
}

export const GET_ALL_FAMILIES = gql`
  query allFamilies {
    families {
      organization {
        organization_id
        full_name
        name
      }
      family_id
      display_name
      internal_name
      location_text
      members
      situation
      size
      created_at
      open_need_sum: needs_aggregate(where: { is_available: { _eq: true } }) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
  }
`
