export const formatStringJoin = arr =>
  arr.join(", ").replace(/, ([^,]*)$/, " and $1")

export const formatMoney = (value, currency = "USD", locale = "en-US") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  })
    .format(value)
    .replace(/.00$/, "")
    .replace(/,00$/, "")
}

export const formatMisc = value => {
  if (value === "miscellaneous needs") {
    return "misc needs"
  } else {
    return value
  }
}

export const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(", ")
  const listEnd = arr.slice(-1)
  const conjunction =
    arr.length <= 1 ? "" : useOxfordComma && arr.length > 2 ? ", and " : " and "
  return [listStart, listEnd].join(conjunction)
}

export const formatFamilyNamesFromNeeds = needs => {
  const familyNames = []
  for (let need of needs) {
    familyNames.push(need.family.display_name)
  }
  let uniqueFamilyNames = [...new Set(familyNames)]
  const formattedFamilyNames = makeCommaSeparatedString(uniqueFamilyNames, true)
  return formattedFamilyNames
}
