import React from "react"

const NewLogo = ({ styles, onClick }) => (
  <>
    <svg
      onClick={onClick}
      className={styles.newLogo}
      width="173"
      height="23"
      viewBox="0 0 173 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1202 17.2396H9.50361L4.61664 9.83635L4.61664 17.2396H0L0 2.51628L4.61664 2.51628L9.50361 10.0443L9.50361 2.51628L14.1202 2.51628L14.1202 17.2396Z"
        fill="#04063E"
      />
      <path
        d="M27.3109 11.2712C27.3109 11.5624 27.2762 11.8674 27.2069 12.1863L19.8244 12.1863C19.866 12.7685 19.9977 13.1706 20.2195 13.3924C20.4414 13.6004 20.7325 13.7043 21.093 13.7043C21.5921 13.7043 21.9525 13.4756 22.1743 13.0181H27.0613C26.895 13.8499 26.5414 14.5986 26.0007 15.264C25.4739 15.9156 24.8015 16.4355 23.9836 16.8237C23.1795 17.198 22.2922 17.3852 21.3217 17.3852C20.1572 17.3852 19.1174 17.1426 18.2024 16.6573C17.3012 16.1721 16.5942 15.4789 16.0812 14.5778C15.5821 13.6627 15.3326 12.5952 15.3326 11.3752C15.3326 10.1552 15.5821 9.09463 16.0812 8.19349C16.5942 7.27848 17.3012 6.57836 18.2024 6.09313C19.1174 5.6079 20.1572 5.36528 21.3217 5.36528C22.4863 5.36528 23.5191 5.6079 24.4203 6.09313C25.3353 6.5645 26.0423 7.24382 26.5414 8.1311C27.0544 9.01838 27.3109 10.0651 27.3109 11.2712ZM22.6318 10.2939C22.6318 9.85021 22.5071 9.52441 22.2575 9.31646C22.008 9.09464 21.696 8.98372 21.3217 8.98372C20.476 8.98372 19.9908 9.42043 19.866 10.2939L22.6318 10.2939Z"
        fill="#04063E"
      />
      <path
        d="M28.0223 2.30832C28.0223 1.64286 28.2649 1.09524 28.7502 0.665461C29.2354 0.22182 29.8801 0 30.6841 0C31.4744 0 32.1121 0.22182 32.5974 0.665461C33.0826 1.09524 33.3252 1.64286 33.3252 2.30832C33.3252 2.95992 33.0826 3.5006 32.5974 3.93038C32.1121 4.36016 31.4744 4.57505 30.6841 4.57505C29.8801 4.57505 29.2354 4.36016 28.7502 3.93038C28.2649 3.5006 28.0223 2.95992 28.0223 2.30832ZM32.9717 5.51085V17.2396H28.355L28.355 5.51085L32.9717 5.51085Z"
        fill="#04063E"
      />
      <path
        d="M38.8624 5.36528C39.6249 5.36528 40.2834 5.52471 40.8379 5.84358C41.3925 6.14858 41.8153 6.57143 42.1065 7.11212V5.51085H46.7023V17.0316C46.7023 18.1269 46.5013 19.1251 46.0993 20.0262C45.6972 20.9274 45.0456 21.6483 44.1445 22.189C43.2572 22.7297 42.1134 23 40.7132 23C38.8277 23 37.3304 22.5564 36.2213 21.6691C35.1261 20.7957 34.5022 19.6034 34.3497 18.0922H38.8832C39.0079 18.7022 39.507 19.0072 40.3804 19.0072C40.9211 19.0072 41.344 18.8686 41.649 18.5913C41.954 18.314 42.1065 17.7942 42.1065 17.0316V15.6383C41.8153 16.179 41.3925 16.6088 40.8379 16.9277C40.2834 17.2327 39.6249 17.3852 38.8624 17.3852C37.9474 17.3852 37.1225 17.1495 36.3877 16.6781C35.6529 16.1929 35.0706 15.4997 34.6408 14.5986C34.2249 13.6835 34.017 12.6091 34.017 11.3752C34.017 10.1414 34.2249 9.07384 34.6408 8.17269C35.0706 7.25768 35.6529 6.5645 36.3877 6.09313C37.1225 5.6079 37.9474 5.36528 38.8624 5.36528ZM40.4012 9.39964C39.9021 9.39964 39.4932 9.57293 39.1743 9.91953C38.8554 10.2661 38.696 10.7514 38.696 11.3752C38.696 11.9991 38.8554 12.4843 39.1743 12.8309C39.4932 13.1775 39.9021 13.3508 40.4012 13.3508C40.9003 13.3508 41.3093 13.1775 41.6282 12.8309C41.9471 12.4843 42.1065 11.9991 42.1065 11.3752C42.1065 10.7514 41.9471 10.2661 41.6282 9.91953C41.3093 9.57293 40.9003 9.39964 40.4012 9.39964Z"
        fill="#04063E"
      />
      <path
        d="M56.5738 5.42767C57.9186 5.42767 58.9653 5.87824 59.7139 6.77938C60.4764 7.68053 60.8577 8.90054 60.8577 10.4394V17.2396H56.2618V11.0217C56.2618 10.4671 56.1093 10.0304 55.8043 9.71157C55.5132 9.37884 55.1181 9.21248 54.619 9.21248C54.106 9.21248 53.704 9.37884 53.4128 9.71157C53.1217 10.0304 52.9761 10.4671 52.9761 11.0217V17.2396H48.3595V1.85081L52.9761 1.85081V7.29928C53.3366 6.74473 53.8149 6.29415 54.411 5.94756C55.021 5.60096 55.7419 5.42767 56.5738 5.42767Z"
        fill="#04063E"
      />
      <path
        d="M70.2836 5.36528C71.1986 5.36528 72.0235 5.6079 72.7583 6.09313C73.4931 6.5645 74.0684 7.25768 74.4843 8.17269C74.9141 9.07384 75.129 10.1414 75.129 11.3752C75.129 12.6091 74.9141 13.6835 74.4843 14.5986C74.0684 15.4997 73.4931 16.1929 72.7583 16.6781C72.0235 17.1495 71.1986 17.3852 70.2836 17.3852C69.5211 17.3852 68.8626 17.2327 68.308 16.9277C67.7673 16.6088 67.3514 16.179 67.0603 15.6383V17.2396H62.4437V1.85081L67.0603 1.85081V7.11212C67.3514 6.57143 67.7673 6.14858 68.308 5.84358C68.8626 5.52471 69.5211 5.36528 70.2836 5.36528ZM68.7447 9.39964C68.2456 9.39964 67.8367 9.57293 67.5178 9.91953C67.1989 10.2661 67.0395 10.7514 67.0395 11.3752C67.0395 11.9991 67.1989 12.4843 67.5178 12.8309C67.8367 13.1775 68.2456 13.3508 68.7447 13.3508C69.2438 13.3508 69.6528 13.1775 69.9717 12.8309C70.2906 12.4843 70.45 11.9991 70.45 11.3752C70.45 10.7514 70.2906 10.2661 69.9717 9.91953C69.6528 9.57293 69.2438 9.39964 68.7447 9.39964Z"
        fill="#04063E"
      />
      <path
        d="M82.2554 5.36528C83.4338 5.36528 84.4944 5.6079 85.4372 6.09313C86.3799 6.57836 87.1147 7.27848 87.6415 8.19349C88.1822 9.09463 88.4525 10.1552 88.4525 11.3752C88.4525 12.5952 88.1822 13.6627 87.6415 14.5778C87.1147 15.4789 86.3799 16.1721 85.4372 16.6573C84.4944 17.1426 83.4338 17.3852 82.2554 17.3852C81.077 17.3852 80.0164 17.1426 79.0737 16.6573C78.131 16.1721 77.3892 15.4789 76.8486 14.5778C76.3217 13.6627 76.0583 12.5952 76.0583 11.3752C76.0583 10.1552 76.3217 9.09463 76.8486 8.19349C77.3892 7.27848 78.131 6.57836 79.0737 6.09313C80.0164 5.6079 81.077 5.36528 82.2554 5.36528ZM82.2554 9.35805C81.8256 9.35805 81.4652 9.53134 81.174 9.87794C80.8829 10.2107 80.7373 10.7098 80.7373 11.3752C80.7373 12.0407 80.876 12.5467 81.1533 12.8933C81.4444 13.226 81.8118 13.3924 82.2554 13.3924C82.6852 13.3924 83.0457 13.226 83.3368 12.8933C83.6279 12.5467 83.7735 12.0407 83.7735 11.3752C83.7735 10.7098 83.6279 10.2107 83.3368 9.87794C83.0457 9.53134 82.6852 9.35805 82.2554 9.35805Z"
        fill="#04063E"
      />
      <path
        d="M94.1036 7.6736C94.5749 6.99427 95.1503 6.45359 95.8296 6.05154C96.5089 5.63562 97.216 5.42767 97.9508 5.42767V10.3562H96.6406C95.7672 10.3562 95.1226 10.5087 94.7067 10.8137C94.3046 11.1187 94.1036 11.6594 94.1036 12.4358V17.2396H89.4869V5.51085L94.1036 5.51085V7.6736Z"
        fill="#04063E"
      />
      <path
        d="M122.372 12.894C122.372 13.7397 122.15 14.5022 121.707 15.1815C121.277 15.8608 120.646 16.4015 119.814 16.8036C118.982 17.1918 117.991 17.3859 116.84 17.3859C115.08 17.3859 113.624 16.9769 112.473 16.1589C111.336 15.3271 110.72 14.114 110.622 12.5197H115.53C115.6 13.3654 115.974 13.7882 116.653 13.7882C116.889 13.7882 117.076 13.7258 117.215 13.601C117.367 13.4763 117.443 13.296 117.443 13.0604C117.443 12.7138 117.263 12.4434 116.903 12.2493C116.556 12.0414 115.988 11.8057 115.198 11.5423C114.269 11.2373 113.492 10.9253 112.868 10.6065C112.258 10.2876 111.725 9.81624 111.267 9.19237C110.81 8.5685 110.581 7.75747 110.581 6.75928C110.581 5.80268 110.823 4.99164 111.309 4.32618C111.808 3.64686 112.48 3.1339 113.326 2.7873C114.185 2.44071 115.149 2.26741 116.216 2.26741C117.977 2.26741 119.377 2.6764 120.417 3.49436C121.457 4.31232 122.025 5.49074 122.122 7.02962H117.152C117.083 6.25325 116.764 5.86506 116.196 5.86506C116.002 5.86506 115.842 5.92745 115.717 6.05222C115.593 6.16313 115.53 6.33643 115.53 6.57211C115.53 6.89098 115.704 7.15439 116.05 7.36235C116.411 7.55644 116.965 7.78519 117.714 8.04861C118.657 8.38134 119.433 8.71407 120.043 9.0468C120.667 9.36566 121.207 9.84396 121.665 10.4817C122.136 11.1056 122.372 11.9097 122.372 12.894Z"
        fill="#0016DB"
      />
      <path
        d="M131.792 5.42835C133.137 5.42835 134.184 5.87893 134.933 6.78007C135.695 7.68122 136.076 8.90123 136.076 10.4401V17.2403H131.481V11.0224C131.481 10.4678 131.328 10.0311 131.023 9.71226C130.732 9.37953 130.337 9.21316 129.838 9.21316C129.325 9.21316 128.923 9.37953 128.632 9.71226C128.34 10.0311 128.195 10.4678 128.195 11.0224V17.2403H123.578V1.8515H128.195V7.29996C128.555 6.74541 129.034 6.29484 129.63 5.94824C130.24 5.60165 130.961 5.42835 131.792 5.42835Z"
        fill="#0016DB"
      />
      <path
        d="M141.925 5.36597C142.688 5.36597 143.346 5.5254 143.901 5.84427C144.456 6.14927 144.878 6.57211 145.17 7.1128V5.51154H149.765V17.2403H145.17V15.639C144.878 16.1797 144.456 16.6095 143.901 16.9283C143.346 17.2334 142.688 17.3859 141.925 17.3859C141.01 17.3859 140.186 17.1502 139.451 16.6788C138.716 16.1936 138.134 15.5004 137.704 14.5992C137.288 13.6842 137.08 12.6098 137.08 11.3759C137.08 10.142 137.288 9.07452 137.704 8.17338C138.134 7.25837 138.716 6.56518 139.451 6.09381C140.186 5.60858 141.01 5.36597 141.925 5.36597ZM143.464 9.40032C142.965 9.40032 142.556 9.57362 142.237 9.92021C141.919 10.2668 141.759 10.752 141.759 11.3759C141.759 11.9998 141.919 12.485 142.237 12.8316C142.556 13.1782 142.965 13.3515 143.464 13.3515C143.963 13.3515 144.372 13.1782 144.691 12.8316C145.01 12.485 145.17 11.9998 145.17 11.3759C145.17 10.752 145.01 10.2668 144.691 9.92021C144.372 9.57362 143.963 9.40032 143.464 9.40032Z"
        fill="#0016DB"
      />
      <path
        d="M155.623 7.67428C156.095 6.99496 156.67 6.45427 157.349 6.05222C158.029 5.63631 158.736 5.42835 159.47 5.42835V10.3569H158.16C157.287 10.3569 156.642 10.5094 156.226 10.8144C155.824 11.1194 155.623 11.6601 155.623 12.4365V17.2403H151.007V5.51154H155.623V7.67428Z"
        fill="#0016DB"
      />
      <path
        d="M172.068 11.2719C172.068 11.5631 172.034 11.8681 171.964 12.1869H164.582C164.624 12.7692 164.755 13.1713 164.977 13.3931C165.199 13.601 165.49 13.705 165.851 13.705C166.35 13.705 166.71 13.4763 166.932 13.0188H171.819C171.653 13.8506 171.299 14.5992 170.758 15.2647C170.231 15.9163 169.559 16.4362 168.741 16.8244C167.937 17.1987 167.05 17.3859 166.079 17.3859C164.915 17.3859 163.875 17.1432 162.96 16.658C162.059 16.1728 161.352 15.4796 160.839 14.5784C160.34 13.6634 160.09 12.5959 160.09 11.3759C160.09 10.1559 160.34 9.09532 160.839 8.19417C161.352 7.27917 162.059 6.57905 162.96 6.09381C163.875 5.60858 164.915 5.36597 166.079 5.36597C167.244 5.36597 168.277 5.60858 169.178 6.09381C170.093 6.56518 170.8 7.24451 171.299 8.13179C171.812 9.01907 172.068 10.0658 172.068 11.2719ZM167.389 10.2945C167.389 9.85089 167.265 9.5251 167.015 9.31714C166.766 9.09532 166.454 8.98441 166.079 8.98441C165.234 8.98441 164.748 9.42112 164.624 10.2945H167.389Z"
        fill="#0016DB"
      />
      <path
        d="M104.238 12.5203C104.238 12.5203 106.067 10.983 106.865 9.98581C107.507 9.21714 107.877 8.71855 107.877 7.72136C107.877 6.86959 107.215 6.1217 106.145 6.1217C104.977 6.1217 104.238 7.64146 104.238 7.64146C104.238 7.64146 103.498 6.1217 102.331 6.1217C101.28 6.1217 100.599 6.89037 100.599 7.72136C100.599 8.73932 100.968 9.21714 101.611 9.98581C102.409 10.983 104.238 12.5203 104.238 12.5203Z"
        fill="#0016DB"
      />
    </svg>
  </>
)

export default NewLogo
