import React, { createContext, useContext, useState, useEffect } from "react"

const initialState = {
  selectedNeeds: [],
  setSelectedNeeds: () => {},
  addNewNeed: () => {},
  addMany: () => {},
  deleteNeed: () => {},
  deleteMany: () => {},
  clearSelectedNeeds: () => {},
}

export const NeedsContext = createContext(initialState)

export const useNeedsContext = () => useContext(NeedsContext)

export const NeedsContextProvider = ({ children }) => {
  const [selectedNeeds, setSelectedNeeds] = useState([])

  useEffect(() => {
    setSelectedNeeds(
      JSON.parse(window.localStorage.getItem("SELECTED_NEEDS")) || []
    )
  }, [])

  useEffect(() => {
    window.localStorage.setItem("SELECTED_NEEDS", JSON.stringify(selectedNeeds))
  }, [selectedNeeds])

  const addNewNeed = newNeed => {
    setSelectedNeeds([...selectedNeeds, newNeed])
  }

  const deleteNeed = deleteIndex => {
    const updatedSelectedNeeds = [...selectedNeeds]
    updatedSelectedNeeds.splice(deleteIndex, 1)
    setSelectedNeeds(updatedSelectedNeeds)
  }

  const addMany = array => {
    setSelectedNeeds(
      [...selectedNeeds, ...array].filter(
        (record, index, self) =>
          index === self.findIndex(t => t.need_id === record.need_id)
      )
    )
  }

  const deleteMany = deleteArray => {
    const updatedSelectedNeeds = [...selectedNeeds]
    const deleteIds = deleteArray.map(record => record.need_id)
    deleteIds.forEach(id => {
      const index = updatedSelectedNeeds.findIndex(
        ({ need_id }) => need_id === id
      )
      if (index > -1) updatedSelectedNeeds.splice(index, 1)
    })
    setSelectedNeeds(updatedSelectedNeeds)
  }

  const clearSelectedNeeds = () => {
    setSelectedNeeds([])
  }

  const value = {
    selectedNeeds,
    setSelectedNeeds,
    addNewNeed,
    addMany,
    deleteNeed,
    deleteMany,
    clearSelectedNeeds,
  }

  return <NeedsContext.Provider value={value}>{children}</NeedsContext.Provider>
}
