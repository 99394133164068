import React from "react"
import ComplexCheck from "./complex-check.inline.svg"

const IconsComplex = () => {
  return (
    <>
      <symbol id="complex-housing" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#aaafdb" }} />
        <path d="M70.32,67.81H32.5a1,1,0,0,1-1-1V41.08a1,1,0,0,1,.37-.78L50.78,25.06a1,1,0,0,1,1.26,0L71,40.3a1,1,0,0,1,.37.78V66.81A1,1,0,0,1,70.32,67.81Zm-36.82-2H69.32V41.56L51.41,27.12,33.5,41.56Z" />
        <path
          d="M51.42,58.13a58,58,0,0,0,6.07-5.82c1.48-1.77,2.34-2.92,2.34-5.21a3.74,3.74,0,0,0-4-3.68c-2.69,0-4.4,4.06-4.4,4.06s-1.71-4.06-4.4-4.06a3.8,3.8,0,0,0-4,3.68c0,2.34.86,3.44,2.34,5.21A58,58,0,0,0,51.42,58.13Z"
          style={{ fill: "#fff" }}
        />
        <path d="M51.42,59.13a1,1,0,0,1-.64-.23,58.23,58.23,0,0,1-6.21-6C43,51.05,42,49.75,42,47.1a4.79,4.79,0,0,1,5-4.68c2,0,3.52,1.63,4.4,3,.88-1.34,2.37-3,4.4-3a4.74,4.74,0,0,1,5,4.68c0,2.6-1,3.94-2.58,5.85a58,58,0,0,1-6.19,5.95A1,1,0,0,1,51.42,59.13ZM47,44.42a2.78,2.78,0,0,0-3,2.68c0,2,.64,2.83,2.1,4.56a50.55,50.55,0,0,0,5.3,5.16,50.23,50.23,0,0,0,5.29-5.14c1.39-1.66,2.12-2.61,2.12-4.58a2.76,2.76,0,0,0-3-2.68c-1.65,0-3.1,2.56-3.48,3.45a1,1,0,0,1-.92.61h0a1,1,0,0,1-.92-.61C50.09,46.91,48.67,44.42,47,44.42Z" />
      </symbol>

      <ComplexCheck id="complex-check" />

      <symbol id="complex-unclassified" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#c9afd5" }} />
        <g>
          <path
            d="M50.52,70S57.8,63.7,61,59.62c2.56-3.15,4-5.19,4-9.28a6.53,6.53,0,0,0-6.89-6.55c-4.65,0-7.59,7.24-7.59,7.24s-3-7.24-7.59-7.24A6.64,6.64,0,0,0,36,50.34c0,4.17,1.48,6.13,4,9.28C43.24,63.7,50.52,70,50.52,70Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M50.52,71a1,1,0,0,1-.66-.24c-.3-.26-7.39-6.41-10.59-10.53C36.57,56.9,35,54.8,35,50.34a7.65,7.65,0,0,1,7.9-7.55c3.66,0,6.28,3.65,7.59,6,1.3-2.33,3.93-6,7.59-6A7.56,7.56,0,0,1,66,50.34c0,4.36-1.61,6.65-4.25,9.91-3.19,4.1-10.28,10.25-10.58,10.51A1,1,0,0,1,50.52,71ZM42.93,44.79A5.61,5.61,0,0,0,37,50.34c0,3.8,1.24,5.48,3.81,8.65a94.36,94.36,0,0,0,9.68,9.68C52.37,67,57.66,62.26,60.18,59,62.63,56,64,54.12,64,50.34a5.54,5.54,0,0,0-5.89-5.55c-3.26,0-5.92,4.77-6.67,6.61a1,1,0,0,1-.92.62h0a1,1,0,0,1-.93-.62C48.84,49.56,46.18,44.79,42.93,44.79Z"
            style={{ fill: "#04063e" }}
          />
        </g>
        <path
          d="M64.24,39.6a1,1,0,0,1-.6-.2,1,1,0,0,1-.2-1.4l3.7-4.9a1,1,0,0,1,1.6,1.2L65,39.2A1,1,0,0,1,64.24,39.6Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M71.64,45.52a1,1,0,0,1-.37-1.92L75,42.1A1,1,0,0,1,75.72,44L72,45.45A1,1,0,0,1,71.64,45.52Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M51.65,38.19a1,1,0,0,1-1-1V30a1,1,0,0,1,2,0v7.19A1,1,0,0,1,51.65,38.19Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M39.07,38.1a1,1,0,0,1-.75-.33l-3.7-4.14a1,1,0,0,1,1.49-1.34l3.7,4.14a1,1,0,0,1-.08,1.42A1,1,0,0,1,39.07,38.1Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M30.92,45.51a1,1,0,0,1-.24,0L24.76,44A1,1,0,0,1,24,42.78a1,1,0,0,1,1.21-.73l5.93,1.48a1,1,0,0,1-.25,2Z"
          style={{ fill: "#04063e" }}
        />
      </symbol>

      <symbol id="complex-childcare" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#c9afd5" }} />
        <circle cx="49.91" cy="42.93" r="12.76" style={{ fill: "#fff" }} />
        <path
          d="M54,44.22A1.23,1.23,0,1,0,52.81,43,1.23,1.23,0,0,0,54,44.22Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M46.66,44.22A1.23,1.23,0,1,0,45.44,43,1.23,1.23,0,0,0,46.66,44.22Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M50.16,51.65A3.2,3.2,0,0,1,47,49.58,1,1,0,1,1,48.91,49a1.23,1.23,0,0,0,1.25.7,1.27,1.27,0,0,0,1.29-.78,1,1,0,0,1,1.83.79A3.27,3.27,0,0,1,50.16,51.65Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M51.93,40.84a5.13,5.13,0,0,1-5.32-5.32,1,1,0,0,1,2,0,3.2,3.2,0,0,0,3.32,3.33,1,1,0,1,1,0,2Zm-3.32-5.32h0Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M60.68,51.47a13.77,13.77,0,1,0-21.56,0,20,20,0,0,0-9.29,16.88,1,1,0,0,0,2,0,18.05,18.05,0,0,1,8.66-15.4,13.68,13.68,0,0,0,18.82,0,18,18,0,0,1,8.63,15.38,1,1,0,0,0,2,0A20,20,0,0,0,60.68,51.47ZM49.91,54.69A11.76,11.76,0,1,1,61.67,42.93,11.78,11.78,0,0,1,49.91,54.69Z"
          style={{ fill: "#04063e" }}
        />
      </symbol>

      <symbol id="complex-groceries" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#f2c4ba" }} />
        <path
          d="M50.16,70.17c11.13,0,20.16-9.48,20.16-21.17H30C30,60.69,39,70.17,50.16,70.17Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M50.16,71.17C38.49,71.17,29,61.22,29,49a1,1,0,0,1,1-1H70.32a1,1,0,0,1,1,1C71.32,61.22,61.82,71.17,50.16,71.17ZM31,50c.5,10.66,8.89,19.17,19.14,19.17S68.8,60.66,69.29,50Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M43.1,45.16a1,1,0,0,1-1-1,3.53,3.53,0,0,1,1.58-2.84c.59-.5.87-.77.87-1.32s-.28-.82-.87-1.32a3.57,3.57,0,0,1-1.58-2.85A3.53,3.53,0,0,1,43.68,33c.59-.51.87-.77.87-1.33a1,1,0,1,1,2,0A3.55,3.55,0,0,1,45,34.51c-.59.5-.87.77-.87,1.32s.28.82.87,1.32a3.35,3.35,0,0,1,0,5.69c-.59.5-.87.77-.87,1.32A1,1,0,0,1,43.1,45.16Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M48.92,45.16a1,1,0,0,1-1-1,3.53,3.53,0,0,1,1.58-2.84c.59-.5.87-.77.87-1.32s-.28-.82-.87-1.32a3.57,3.57,0,0,1-1.58-2.85A3.53,3.53,0,0,1,49.5,33c.59-.51.87-.77.87-1.33a1,1,0,0,1,2,0,3.55,3.55,0,0,1-1.58,2.85c-.59.5-.87.77-.87,1.32s.28.82.87,1.32a3.35,3.35,0,0,1,0,5.69c-.59.5-.87.77-.87,1.32A1,1,0,0,1,48.92,45.16Z"
          style={{ fill: "#04063e" }}
        />
        <path
          d="M54.44,45.16a1,1,0,0,1-1-1A3.53,3.53,0,0,1,55,41.32c.59-.5.87-.77.87-1.32s-.28-.82-.87-1.32a3.57,3.57,0,0,1-1.58-2.85A3.53,3.53,0,0,1,55,33c.59-.51.87-.77.87-1.33a1,1,0,1,1,2,0,3.55,3.55,0,0,1-1.58,2.85c-.59.5-.87.77-.87,1.32s.28.82.87,1.32a3.35,3.35,0,0,1,0,5.69c-.59.5-.87.77-.87,1.32A1,1,0,0,1,54.44,45.16Z"
          style={{ fill: "#04063e" }}
        />
      </symbol>

      <symbol id="complex-transport" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#f2c4ba" }} />
        <circle cx="33.82" cy="58.72" r="5.28" style={{ fill: "#fff" }} />
        <circle cx="67.34" cy="58.72" r="5.28" style={{ fill: "#fff" }} />
        <path
          d="M67,43.94C66.46,37.36,60,33,50.58,33c-8.69,0-15.81,5.56-16.44,12.57-7.89,0-14.3,5.74-14.3,12.73a1,1,0,0,0,1.51.84h6.21a6.28,6.28,0,0,0,12.53,0h21a6.27,6.27,0,0,0,12.52,0H79a1,1,0,0,0,.52.16,1,1,0,0,0,1-1A14.41,14.41,0,0,0,67,43.94ZM33.82,63a4.29,4.29,0,1,1,4.29-4.28A4.28,4.28,0,0,1,33.82,63Zm33.52,0a4.29,4.29,0,1,1,4.28-4.28A4.29,4.29,0,0,1,67.34,63Zm6.07-5.86a6.27,6.27,0,0,0-12.15,0H39.9a6.27,6.27,0,0,0-12.15,0H21.91c.68-5.38,5.95-9.57,12.33-9.57a1,1,0,0,0,.42-.1,1,1,0,0,0,1.42-.9c0-6.38,6.51-11.57,14.5-11.57,7.22,0,14.5,3.06,14.5,9.89a1,1,0,0,0,1,1A12.43,12.43,0,0,1,78.43,57.14Z"
          style={{ fill: "#04063e" }}
        />
      </symbol>

      <symbol id="complex-bills" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#f2e39a" }} />}
        <path
          d="M35.83,39.84H54.55v6.4a9.36,9.36,0,1,1-18.72,0Z"
          style={{ fill: "#fff" }}
        />
        <path d="M70.31,46.72H65.39A7.42,7.42,0,0,0,58,54.12v6.16a5.65,5.65,0,0,1-11.3,0v-3.8a10.36,10.36,0,0,0,8.87-10.24v-6.4a1,1,0,0,0-1-1H50.61V32a1,1,0,0,0-2,0v6.87H41.74V32a1,1,0,0,0-2,0v6.87H35.83a1,1,0,0,0-1,1v6.4a10.37,10.37,0,0,0,9.85,10.34v3.7a7.65,7.65,0,0,0,15.3,0V54.12a5.41,5.41,0,0,1,5.41-5.4h4.92a1,1,0,0,0,0-2Zm-33.48-.48v-5.4H53.55v5.4a8.36,8.36,0,1,1-16.72,0Z" />
      </symbol>

      <symbol id="complex-education" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" style={{ fill: "#f2e39a" }} />
        <path
          d="M75,44.67,50.49,35,26,44.67,50.49,55.41Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M76,44.65a1,1,0,0,0-.63-.91L50.85,34.07a1,1,0,0,0-.73,0L25.63,43.74a1,1,0,0,0,0,1.84l8.78,3.85v11.4a1,1,0,0,0,.41.81c2.07,1.51,8,4.36,15.7,4.36S63.8,63.13,65.7,61.61a1,1,0,0,0,.37-.78V49.66L74,46.2V64.48a1,1,0,0,0,2,0V45.2a1,1,0,0,0,0-.24A.65.65,0,0,0,76,44.65ZM64.07,60.33A24.86,24.86,0,0,1,50.49,64a27.61,27.61,0,0,1-14.11-3.69v-10l13.71,6a.9.9,0,0,0,.4.09,1,1,0,0,0,.4-.09l13.18-5.78Zm-13.58-6L28.6,44.71l21.89-8.63,21.88,8.63Z"
          style={{ fill: "#04063e" }}
        />
      </symbol>

      <symbol id="complex-holiday" viewBox="0 0 75 75">
        <path
          d="M23.385 56.44h28.701l2.933 11.388H20.447l2.938-11.388z"
          fill="#EAB818"
        />
        <path
          d="M28.5 56.44h18.469l1.887 11.388H26.61L28.5 56.44z"
          fill="#ED9015"
        />
        <path
          d="M23.385 56.663v-.223h28.701v.223c6.686-4.582 11.077-12.266 11.077-20.991 0-14.046-11.378-25.435-25.435-25.435-14.042 0-25.434 11.388-25.434 25.435 0 8.725 4.394 16.409 11.09 20.99z"
          fill="#fff"
        />
        <path
          d="M25.615 56.663v-.19h24.24v.19c5.645-3.869 9.353-10.359 9.353-17.727 0-11.862-9.61-21.481-21.48-21.481-11.859 0-21.482 9.619-21.482 21.48 0 7.37 3.714 13.86 9.369 17.728z"
          fill="#F0F6FC"
        />
        <path
          d="M27.675 56.663v-.157h20.118v.157c4.686-3.212 7.763-8.596 7.763-14.714 0-9.844-7.975-17.828-17.828-17.828-9.842 0-17.828 7.984-17.828 17.828 0 6.118 3.08 11.502 7.775 14.714z"
          fill="#D9F1FF"
        />
        <path
          d="M29.81 55.544v-.122h15.845v.122c3.69-2.53 6.114-6.771 6.114-11.586 0-7.754-6.28-14.042-14.04-14.042-7.75 0-14.04 6.288-14.04 14.042-.002 4.815 2.423 9.057 6.12 11.586z"
          fill="#97D7FF"
        />
        <path
          d="M37.95 30.81c-7.75 0-14.04 6.288-14.04 14.042 0 4.816 2.427 9.057 6.124 11.586v-.123h7.918V30.81h-.001z"
          fill="#AEDCFF"
        />
        <path
          d="M55.037 68.52H20.448a.69.69 0 01-.67-.864l2.938-11.389a.692.692 0 01.67-.518h28.701c.315 0 .59.213.67.518l2.895 11.242a.691.691 0 01-.615 1.01zM21.34 67.136h32.787L51.55 57.13H23.92L21.34 67.136z"
          fill="#093260"
        />
        <path
          d="M52.31 57.354a.68.68 0 01-.509-.223H24.117a.686.686 0 01-.899.102 26.122 26.122 0 01-11.391-21.562C11.826 21.266 23.546 9.546 37.952 9.546s26.126 11.72 26.126 26.126c0 8.62-4.253 16.68-11.377 21.562a.697.697 0 01-.391.12zM37.952 10.93c-13.644 0-24.743 11.1-24.743 24.743 0 7.968 3.842 15.43 10.308 20.083a.612.612 0 01.093-.007h28.7c.031 0 .062.002.092.007a24.752 24.752 0 0010.294-20.083c0-13.644-11.1-24.743-24.744-24.743z"
          fill="#093260"
        />
        <path
          d="M22.224 28.233h-.698a.69.69 0 01-.598-.344l-.352-.605a.69.69 0 010-.695l.352-.605a.693.693 0 01.598-.343h.698a.69.69 0 01.6.348l.345.605a.688.688 0 010 .685l-.344.604a.69.69 0 01-.6.35zM59.295 36.71h-.699a.692.692 0 01-.598-.345l-.35-.605a.695.695 0 010-.693l.35-.604a.694.694 0 01.598-.345h.699c.249 0 .477.133.6.348l.346.605a.69.69 0 010 .686l-.346.604a.689.689 0 01-.6.349zM18.054 36.89a.687.687 0 01-.346-.093l-.604-.349a.692.692 0 01-.345-.598v-.697a.69.69 0 01.345-.598l.604-.35a.69.69 0 01.691 0l.604.35a.692.692 0 01.346.598v.697a.69.69 0 01-.346.598l-.604.35a.687.687 0 01-.345.093zM56.696 30.786a.687.687 0 01-.345-.092l-.605-.348a.693.693 0 01-.346-.599v-.699a.69.69 0 01.345-.598l.605-.35a.691.691 0 01.691 0l.605.35a.69.69 0 01.346.598v.699a.69.69 0 01-.347.6l-.605.347a.688.688 0 01-.344.092zM26.38 18.552c-1.19 0-1.19 1.844 0 1.844 1.189 0 1.189-1.844 0-1.844zM43.432 14.433c-1.19 0-1.19 1.844 0 1.844 1.189 0 1.189-1.844 0-1.844zM52.159 22.355c-1.19 0-1.19 1.843 0 1.843 1.189 0 1.189-1.843 0-1.843zM35.222 14.433c-1.19 0-1.19 1.844 0 1.844 1.189 0 1.189-1.844 0-1.844zM22.548 20.511c-1.19 0-1.19 1.844 0 1.844 1.189 0 1.189-1.844 0-1.844zM51.295 25.524c-1.189 0-1.189 1.843 0 1.843 1.19 0 1.19-1.843 0-1.843zM44.67 19.503c-1.19 0-1.19 1.843 0 1.843 1.189 0 1.189-1.843 0-1.843zM48.99 17.343c-1.188 0-1.188 1.844 0 1.844 1.19 0 1.19-1.844 0-1.844zM32.083 17.976c-1.19 0-1.19 1.844 0 1.844 1.189 0 1.189-1.844 0-1.844zM39.053 17.141c-1.188 0-1.188 1.844 0 1.844 1.19 0 1.19-1.844 0-1.844z"
          fill="#D9EBFC"
        />
        <path
          d="M28.231 65.872a.673.673 0 01-.672-.672 4.184 4.184 0 00-4.179-4.18.673.673 0 010-1.344 5.53 5.53 0 015.524 5.523.673.673 0 01-.673.673z"
          fill="#093260"
        />
        <path
          d="M28.231 65.872a.673.673 0 01-.672-.672 5.53 5.53 0 015.523-5.524.673.673 0 010 1.345 4.183 4.183 0 00-4.178 4.178.673.673 0 01-.673.673z"
          fill="#093260"
        />
        <path
          d="M28.231 65.872a1.172 1.172 0 010-2.342 1.17 1.17 0 010 2.342zm0-1.345a.177.177 0 00-.175.173c-.001.192.35.194.35 0a.178.178 0 00-.175-.173zM37.932 65.872a.673.673 0 01-.672-.672 4.183 4.183 0 00-4.178-4.18.673.673 0 010-1.344 5.53 5.53 0 015.523 5.523.673.673 0 01-.673.673z"
          fill="#093260"
        />
        <path
          d="M37.932 65.872a.673.673 0 01-.672-.672 5.53 5.53 0 015.521-5.524.673.673 0 010 1.345 4.182 4.182 0 00-4.176 4.178.673.673 0 01-.673.673z"
          fill="#093260"
        />
        <path
          d="M37.932 65.872A1.174 1.174 0 0136.76 64.7a1.173 1.173 0 012.344 0c0 .647-.525 1.172-1.17 1.172zm0-1.345a.174.174 0 00-.174.173c0 .193.347.194.347 0a.174.174 0 00-.173-.173zM47.632 65.872a.673.673 0 01-.673-.672 4.183 4.183 0 00-4.178-4.18.673.673 0 010-1.344 5.53 5.53 0 015.523 5.523.673.673 0 01-.672.673z"
          fill="#093260"
        />
        <path
          d="M47.632 65.872a.673.673 0 01-.673-.672 5.53 5.53 0 015.524-5.524.673.673 0 010 1.345 4.184 4.184 0 00-4.179 4.178.673.673 0 01-.672.673z"
          fill="#093260"
        />
        <path
          d="M47.632 65.872a1.172 1.172 0 010-2.342 1.172 1.172 0 010 2.342zm0-1.345a.176.176 0 00-.174.173c-.001.194.35.192.35 0a.177.177 0 00-.176-.173z"
          fill="#093260"
        />
        <path
          d="M38.71 34.166l-12.826 6.531v15.688h25.654V41.576l-12.827-7.41z"
          fill="#FF7B51"
        />
        <path
          d="M25.884 40.697v6.871c0 .943.767 1.703 1.706 1.703.944 0 1.71-.76 1.71-1.703l.217-4.707 9.194-4.74 12.828 7.406v-3.951l-12.828-7.41-12.826 6.531zM43.244 26.732v1.459l7.566 4.37v-5.829"
          fill="#FF7B51"
        />
        <path d="M52.957 21.908H41.09v4.824h11.868v-4.824z" fill="#D9F1FF" />
        <path d="M52.957 24.32H41.09v2.412h11.868V24.32z" fill="#AEDCFF" />
        <path
          d="M37.74 25.156l-15.693 9.062v8.969l2.867-1.658v2.043c0 .943.766 1.703 1.705 1.703.944 0 1.71-.76 1.71-1.703l.218-4.707 9.193-4.74 15.699 9.062v-8.969l-15.7-9.062z"
          fill="#D9F1FF"
        />
        <path
          d="M22.047 37.977v5.211l2.867-1.658v2.043c0 .943.766 1.703 1.705 1.703.944 0 1.71-.76 1.71-1.703l.218-4.707 9.193-4.74 15.699 9.062v-5.211l-15.7-9.061-15.692 9.061z"
          fill="#AEDCFF"
        />
        <path
          d="M38.217 50.816s2.496-2.004 3.584-3.303c.876-1.002 1.38-1.652 1.38-2.952 0-1.11-.902-2.085-2.362-2.085-1.593 0-2.602 2.302-2.602 2.302s-1.009-2.302-2.602-2.302c-1.433 0-2.363 1.002-2.363 2.085 0 1.327.505 1.95 1.381 2.952 1.088 1.3 3.584 3.303 3.584 3.303z"
          fill="#fff"
          stroke="#093260"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.81 57.135H25.156a.75.75 0 01-.75-.75v-13.66a.75.75 0 011.5 0v12.91H50.06V42.009l-12.453-7.193a.75.75 0 01.75-1.298l12.828 7.41a.75.75 0 01.375.649v14.809a.75.75 0 01-.75.749zM50.568 33.311a.748.748 0 01-.375-.101l-7.566-4.369a.75.75 0 01-.375-.649v-1.459a.75.75 0 011.5 0v1.026l6.066 3.503v-4.529a.75.75 0 011.5 0v5.828a.748.748 0 01-.75.75z"
          fill="#093260"
        />
        <path
          d="M52.716 27.482h-11.87a.75.75 0 01-.75-.75v-4.824a.75.75 0 01.75-.75h11.869a.75.75 0 01.75.75v4.824a.749.749 0 01-.75.75zm-11.12-1.5h10.369v-3.324H41.597v3.324z"
          fill="#093260"
        />
        <path
          d="M26.619 46.025a2.457 2.457 0 01-2.455-2.453v-.743l-1.742 1.008a.75.75 0 01-1.125-.649v-8.969c0-.268.143-.516.375-.649l15.693-9.062a.75.75 0 01.75 0l15.699 9.062a.75.75 0 01.375.649v8.969a.748.748 0 01-1.125.649L37.719 34.98l-8.443 4.354-.197 4.273c0 1.318-1.103 2.418-2.46 2.418zm-1.705-5.246a.75.75 0 01.75.75v2.043a.958.958 0 001.916 0l.218-4.741a.75.75 0 01.405-.633l9.193-4.74a.75.75 0 01.719.017l14.574 8.413v-7.237l-14.95-8.629-14.942 8.629v7.236l1.742-1.008a.745.745 0 01.375-.1z"
          fill="#093260"
        />
      </symbol>
    </>
  )
}

export default IconsComplex
