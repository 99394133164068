import React, { useState } from "react"
import ButtonCustom from "../../ButtonCustom"
import Icon from "../../Icon"
import { Checkbox, Form, Input } from "antd"
import styles from "../styles.module.scss"
import { formatMoney } from "../../../utils/format"
import Loading from "../../Loading"
import { getTransactionFees } from "../../../../functions/utils/calc"
import redirectToGiftCardCheckout from "../../../utils/redirect-gift-card"
import { CustomInputNumber } from "../../CustomInputNumber"

export const GiveGiftContent = ({ stage, setStage }) => {
  const [value, setValue] = useState(null)
  const [isCoveringFees, setIsCoveringFees] = useState(true)
  const [subscribeMail, setSubscribeMail] = useState(true)
  const [paying, setPaying] = useState(false)

  const onFinish = values => {
    if (!paying) {
      redirectToGiftCardCheckout(
        value,
        subscribeMail,
        values.senderName,
        values.recipientName,
        values.deliveryEmail,
        isCoveringFees
      )
      setPaying(true)
    }
  }

  const AmountChip = ({ value, selectChip, selected }) => (
    <div
      className={`${styles.amountChip} ${selected &&
        styles.amountChipSelected}`}
      onClick={selectChip}
    >
      <span>{formatMoney(value)}</span>
    </div>
  )

  switch (stage) {
    case 0:
      return (
        <>
          <div className={styles.header}>
            <Icon icon="unclassified" aesthetic="simple" />
            <h3 className={styles.heading}>Give a Gift Card</h3>
            <p className={styles.subheading}>
              Give the gift of giving with a NeighborShare Gift Card
            </p>
          </div>
          <div className={styles.actions}>
            <p className={styles.selectText}>Select an amount</p>
            <div className={styles.chipContainer}>
              <AmountChip
                value={10}
                selectChip={() => setValue(10)}
                selected={value === 10}
              />
              <AmountChip
                value={25}
                selectChip={() => setValue(25)}
                selected={value === 25}
              />
              <AmountChip
                value={50}
                selectChip={() => setValue(50)}
                selected={value === 50}
              />
            </div>
            <CustomInputNumber value={value} setValue={setValue} />
            <div className={styles.applyDonation}>
              <div className={styles.checkboxes}>
                <Checkbox
                  checked={subscribeMail}
                  onClick={() => setSubscribeMail(!subscribeMail)}
                >
                  Stay updated by subscribing to NeighborShare news
                </Checkbox>
                <div className={`${styles.text} ${styles.further}`}>
                  Help your gift go further!
                </div>
                <Checkbox
                  checked={isCoveringFees}
                  onClick={() => setIsCoveringFees(!isCoveringFees)}
                >
                  Cover transaction fee
                  {!value || value === 0
                    ? ""
                    : ` ($${getTransactionFees(value).toFixed(2)})`}
                </Checkbox>
              </div>
              <ButtonCustom
                className={styles.checkoutButton}
                iconClassName={styles.checkoutButtonIcon}
                size="medium"
                onClick={() => {
                  value && setStage(stage + 1)
                }}
              >
                Continue
              </ButtonCustom>
            </div>
          </div>
        </>
      )
    case 1:
      return (
        <>
          <div className={styles.header}>
            <div className={styles.dollarMonthlyDonation}>
              <span className={styles.dollarText}>${value}</span>
            </div>
            <p className={styles.headerSubtitle} style={{ marginTop: "1rem" }}>
              Gift Card
            </p>
          </div>
          <div
            className={`${styles.donationFormWrapper} ${styles.giftWrapper}`}
          >
            <div className={`${styles.text} ${styles.details}`}>
              Enter Details
            </div>
            <Form onFinish={onFinish}>
              <div className={styles.actions}>
                <div className={styles.inputDetails}>
                  <Form.Item
                    className={styles.inputForm}
                    name="senderName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input
                      name="senderName"
                      placeholder={"Your name"}
                      className={styles.inputFrame}
                    />
                  </Form.Item>
                  <Form.Item
                    className={styles.inputForm}
                    name="recipientName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your recipient's name",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input
                      name="recipientName"
                      placeholder={"Recipient's name"}
                      className={styles.inputFrame}
                    />
                  </Form.Item>
                  <div className={`${styles.text} ${styles.mailed}`}>
                    Where would you like your gift emailed?
                  </div>
                  <div className={`${styles.text} ${styles.hint}`}>
                    Hint: enter recipient’s email for immediate delivery, and
                    your own address if you’d like to deliver at a time of your
                    choosing.
                  </div>
                  <Form.Item
                    className={`${styles.inputForm} ${styles.deliveryEmail}`}
                    name="deliveryEmail"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                        validateTrigger: "onSubmit",
                      },
                      {
                        required: true,
                        message: "Please enter your delivery email address",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input
                      name="deliveryEmail"
                      placeholder={"Delivery email"}
                      className={styles.inputFrame}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item>
                    <ButtonCustom
                      className={styles.checkoutButton}
                      iconClassName={styles.checkoutButtonIcon}
                      size="medium"
                    >
                      {paying ? <Loading /> : "Continue to payment"}
                    </ButtonCustom>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </>
      )
  }
}
