import { useState, useCallback } from "react"

export const useFilters = () => {
  const initialState = {
    priceRange: [0, 1000],
    distanceRange: [0, 50],
    needs: [],
    locations: [],
    region: null,
    organizations: [],
  }

  const [filters, setFilters] = useState(initialState)

  const filtersActive = () => {
    return (
      filters.priceRange[1] !== 1000 ||
      filters.needs.length !== 0 ||
      filters.locations.length !== 0 ||
      filters.distanceRange[1] !== 50 ||
      filters.organizations.length !== 0
    )
  }

  const resetFiltersExceptRegion = useCallback(
    () => setFilters({ ...initialState, region: filters.region }),
    [initialState, filters]
  )

  const setPriceRange = useCallback(
    (min, max) => setFilters({ ...filters, priceRange: [min, max] }),
    [filters]
  )

  const setNeeds = useCallback(needs => setFilters({ ...filters, needs }), [
    filters,
  ])

  const setLocations = useCallback(
    locations => setFilters({ ...filters, locations }),
    [filters]
  )

  const setNeedsAndLocations = useCallback((needs, locations) => {
    setFilters(previous => ({
      ...previous,
      needs,
      locations,
    }))
  }, [])

  const setDistanceRange = useCallback(
    (min, max) => setFilters({ ...filters, distanceRange: [min, max] }),
    [filters]
  )

  const setRegion = useCallback(region => setFilters({ ...filters, region }), [
    filters,
  ])

  const setOrganizations = useCallback(
    organizations => setFilters({ ...filters, organizations }),
    [filters]
  )

  return {
    filters,
    resetFiltersExceptRegion,
    filtersActive,
    setDistanceRange,
    setPriceRange,
    setNeeds,
    setRegion,
    setLocations,
    setNeedsAndLocations,
    setOrganizations,
  }
}
