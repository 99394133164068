import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

const Icon = ({ aesthetic, icon, className }) => (
  <svg className={`${styles[aesthetic]} ${className}`}>
    <use xlinkHref={`#${aesthetic}-${icon}`} />
  </svg>
)

Icon.propTypes = {
  aesthetic: PropTypes.oneOf(["minimal", "simple", "complex", "generic"])
    .isRequired,
  icon: PropTypes.string.isRequired,
}

export default Icon
