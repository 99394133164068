import React from "react"

export const BitcoinSvg = () => (
  <svg
    width="28"
    height="37"
    viewBox="0 0 28 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2455 14.5363C27.8146 10.7373 24.9201 8.69456 20.9652 7.33156L22.2484 2.18406L19.1164 1.40469L17.8658 6.41625C17.0429 6.20962 16.1983 6.0175 15.3555 5.82537L16.6152 0.781188L13.4832 0L12.1999 5.14569C11.5184 4.98981 10.8478 4.83756 10.1989 4.67444L10.2026 4.65812L5.88156 3.57969L5.04781 6.92556C5.04781 6.92556 7.37325 7.45844 7.32431 7.49106C8.59306 7.80825 8.82144 8.64744 8.78338 9.31444L7.3225 15.1779C7.4095 15.1996 7.52188 15.2322 7.64875 15.2812L7.31706 15.1996L5.26894 23.4139C5.11306 23.7981 4.71975 24.3763 3.83163 24.157C3.86425 24.2023 1.55513 23.5897 1.55513 23.5897L0 27.1748L4.07812 28.1916C4.83575 28.3819 5.57888 28.5813 6.30931 28.768L5.01338 33.9735L8.14356 34.7529L9.42681 29.6054C10.2823 29.8356 11.1124 30.0494 11.9244 30.2524L10.6448 35.3782L13.7768 36.1576L15.0728 30.9629C20.416 31.9743 24.4325 31.5665 26.1236 26.7344C27.4866 22.8447 26.0565 20.5991 23.2453 19.1364C25.2934 18.6651 26.8341 17.3184 27.2455 14.5363ZM20.0861 24.5739C19.1201 28.4653 12.5679 26.361 10.4436 25.8336L12.1655 18.937C14.2898 19.4681 21.0993 20.5175 20.0861 24.5739ZM21.0558 14.4801C20.1731 18.0199 14.7211 16.2201 12.9539 15.7796L14.5127 9.5265C16.2799 9.96694 21.9766 10.788 21.0558 14.4801Z"
      fill="#81829E"
    />
  </svg>
)
