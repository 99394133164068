import React from "react"
import { navigate, Link } from "@reach/router"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

/**
 * LinkCustom unifies handling internal and external links maintaining consistency
 * @param {string} scope wether it links to an internal page or external page
 * @param {string} size [large, small, nav]: define the style to be applied
 * @param {string} url the destination
 */

const LinkCustom = ({ children, scope, size, btnType, url, ...other }) => {
  const handleClick = e => navigate(e.target.getAttribute("href"))
  let customStyle = ""
  if (btnType === "donate") {
    customStyle = styles[btnType]
  } else if (btnType === "non-profits") {
    let camelCaseBtnType = btnType.replace(/-([a-z])/g, function(g) {
      return g[1].toUpperCase()
    })

    customStyle = styles[camelCaseBtnType]
  }
  return (
    <>
      {scope === "internal" && (
        <Link
          className={`${styles.link} ${styles[size]} ${customStyle}`}
          to={url}
          onClick={handleClick}
          {...other}
        >
          {children}
        </Link>
      )}
      {scope === "external" && (
        <a
          className={`${styles.link} ${styles[size]}`}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          {...other}
        >
          {children}
        </a>
      )}
    </>
  )
}

LinkCustom.defaultProps = {
  scope: "internal",
}

LinkCustom.propTypes = {
  children: PropTypes.node.isRequired,
  scope: PropTypes.oneOf(["internal", "external"]),
  size: PropTypes.oneOf(["large", "small", "nav"]).isRequired,
  btnType: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default LinkCustom
