import React, { useState } from "react"
import IconButton from "../IconButton"
import { Modal as AntModal } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import styles from "./styles.module.scss"
import {
  GeneralFundContent,
  GiveGiftContent,
  DonateContent,
  BulkGiftContent,
} from "./contents"
import { BackSvg } from "./icons"

export const ModalType = {
  Donate: "Donate",
  GiveGift: "GiveGift",
  GeneralFund: "GeneralFund",
  BulkGift: "BulkGift",
}

const Modal = ({ modalOpen, handleModalClose, modalType, ...rest }) => {
  const [stage, setStage] = useState(0)

  const renderContent = () => {
    switch (modalType) {
      case ModalType.Donate:
        return <DonateContent stage={stage} setStage={setStage} {...rest} />
      case ModalType.GiveGift:
        return <GiveGiftContent stage={stage} setStage={setStage} />
      case ModalType.GeneralFund:
        return <GeneralFundContent stage={stage} setStage={setStage} />
      case ModalType.BulkGift:
        return <BulkGiftContent stage={stage} setStage={setStage} />
      default:
        return null
    }
  }

  return (
    <AntModal
      className={styles.modal}
      visible={modalOpen}
      onCancel={handleModalClose}
      closable={false}
      footer={null}
    >
      <div className={styles.donationContainer}>
        {stage === 1 && (
          <div className={styles.backBtnSvg} onClick={() => setStage(0)}>
            <IconButton icon={<BackSvg />} />
          </div>
        )}
        {stage === 0 && (
          <IconButton
            icon={<CloseOutlined className={styles.closeButton} />}
            onClick={handleModalClose}
          />
        )}
        <div>{renderContent()}</div>
      </div>
    </AntModal>
  )
}

export default Modal
