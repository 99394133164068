import React from "react"
import { InputNumber } from "antd"
import styles from "./styles.module.scss"

export const CustomInputNumber = ({
  value,
  setValue,
  inputText = "Enter a custom amount",
}) => (
  <div className={styles.customAmount}>
    <InputNumber
      value={value === null ? inputText : Math.round(value)}
      onFocus={() => value === null && setValue(0)}
      onChange={setValue}
      className={styles.customAmountChip}
      formatter={inputValue =>
        `$ ${inputValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={value => value.replace(/\$\s?|(,*)/g, "")}
      min={1}
      step={1}
    />
  </div>
)
