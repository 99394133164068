import React, { useState } from "react"
import ButtonCustom from "../../ButtonCustom"
import Icon from "../../Icon"
import { Checkbox, InputNumber, Form, Input } from "antd"
import styles from "../styles.module.scss"
import Loading from "../../Loading"
import { getTransactionFees } from "../../../../functions/utils/calc"
import redirectToBulkGiftCardCheckout from "../../../utils/redirect-bulk-gift-card"

export const BulkGiftContent = ({ stage, setStage }) => {
  const [value, setValue] = useState(null)
  const [isCoveringFees, setIsCoveringFees] = useState(true)
  const [subscribeMail, setSubscribeMail] = useState(true)
  const [paying, setPaying] = useState(false)

  const onFinish = values => {
    if (!paying) {
      redirectToBulkGiftCardCheckout(
        value,
        subscribeMail,
        values.bulkPurchaserName,
        isCoveringFees
      )
      setPaying(true)
    }
  }

  const CustomChip = (
    <InputNumber
      value={value === null ? "Enter total amount" : Math.round(value)}
      onFocus={() => value === null && setValue(0)}
      onChange={setValue}
      className={styles.customAmountChipMatchInput}
      formatter={inputValue =>
        `$ ${inputValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={value => value.replace(/\$\s?|(,*)/g, "")}
      min={1}
      step={1}
    />
  )

  return (
    <>
      <div className={styles.header}>
        <Icon icon="unclassified" aesthetic="simple" />
        <h3 className={styles.heading}>Bulk Giving Card Purchase</h3>
        <p className={styles.subheading}>Thank you for your order!</p>
      </div>
      <div className={`${styles.donationFormWrapper} ${styles.giftWrapper}`}>
        <div className={`${styles.text} ${styles.details}`}>
          Enter the total $ amount of Giving Cards purchased
        </div>
        <Form onFinish={onFinish}>
          <div className={styles.actions}>
            <div className={styles.inputDetails}>
              <Form.Item
                className={styles.inputForm}
                name="totalAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter the total amount to pay",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <div className={styles.customAmount}>{CustomChip}</div>
              </Form.Item>
              <Form.Item
                className={styles.inputForm}
                name="bulkPurchaserName"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter your company or individual purchaser name",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  name="bulkPurchaseName"
                  placeholder={"Company or Individual Purchaser Name"}
                  className={styles.inputFrame}
                />
              </Form.Item>
            </div>
            <div className={styles.checkboxes}>
              <div className={`${styles.text} ${styles.further}`}>
                Help your gift go further!
              </div>
              <Checkbox
                checked={isCoveringFees}
                onClick={() => setIsCoveringFees(!isCoveringFees)}
              >
                Cover transaction fee
                {!value || value === 0
                  ? ""
                  : ` ($${getTransactionFees(value).toFixed(2)})`}
              </Checkbox>
              <Checkbox
                className={styles.subscribe}
                checked={subscribeMail}
                onClick={() => setSubscribeMail(!subscribeMail)}
              >
                Stay updated by subscribing to NeighborShare news
              </Checkbox>
            </div>
            <div>
              <Form.Item>
                <ButtonCustom
                  className={styles.checkoutButton}
                  iconClassName={styles.checkoutButtonIcon}
                  size="medium"
                >
                  {paying ? <Loading /> : "Continue to payment"}
                </ButtonCustom>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}
