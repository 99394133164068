import React from "react"
import Icon from "../Icon"
import styles from "./styles.module.scss"

const Loading = ({ ...other }) => (
  <div className={styles.loading} {...other}>
    <Icon aesthetic="minimal" icon="heart" />
  </div>
)

export default Loading
