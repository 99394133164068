import React from "react"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk"
import { Auth0Provider } from "@auth0/auth0-react"
import LogRocket from "logrocket"

const client = new ApolloClient({
  uri: process.env.GATSBY_NBS_API_URL,
  headers: {},
  cache: new InMemoryCache(),
})

const optimizely = createInstance({
  sdkKey: process.env.GATSBY_OPTIMIZELY_KEY,
})

export const wrapRootElement = ({ element }) => {
  return (
    <OptimizelyProvider optimizely={optimizely} timeout={500}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </OptimizelyProvider>
  )
}

export const onClientEntry = () => {
  LogRocket.init("3wtvfs/neighborshare")
  LogRocket.getSessionURL(sess => {
    if (window.gtag !== undefined && typeof window !== undefined) {
      window.gtag("event", "LogRocket", {
        hitType: "event",
        eventCategory: "LogRocket",
        eventAction: sess,
      })
    } else {
      console.log("LogRocket connection failed!")
    }
  })
  if (window.gtag !== undefined && typeof window !== undefined) {
    window.gtag("config", "", { optimize_id: "OPT-MZ8472C" })
  }
}
