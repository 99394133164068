import React from "react"

export const GiftCardSvg = () => (
  <svg
    width="100"
    height="70"
    viewBox="8 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9228 43C27.9228 43 35.6038 36.3887 38.9541 32.1003C41.6506 28.7947 43.2032 26.6505 43.2032 22.3621C43.2032 18.6991 40.4249 15.4828 35.9307 15.4828C31.0279 15.4828 27.9228 23.0768 27.9228 23.0768C27.9228 23.0768 24.8176 15.4828 19.9148 15.4828C15.5023 15.4828 12.6423 18.7884 12.6423 22.3621C12.6423 26.7398 14.1949 28.7947 16.8914 32.1003C20.2417 36.3887 27.9228 43 27.9228 43Z"
      fill="#81829E"
    />
    <path
      d="M42.4005 10.0312L46.3062 4.8869"
      stroke="#81829E"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M50.212 16.2503L54.1177 14.6758"
      stroke="#81829E"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M29.1228 8.55358L29.1221 1"
      stroke="#81829E"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15.843 8.45672L11.9373 4.10956"
      stroke="#81829E"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M7.24914 16.2305L1 14.6758"
      stroke="#81829E"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
)
